import { render, staticRenderFns } from "./SingleDraftActivitySlicePage1.vue?vue&type=template&id=5c55e9ee&"
import script from "./SingleDraftActivitySlicePage1.vue?vue&type=script&lang=js&"
export * from "./SingleDraftActivitySlicePage1.vue?vue&type=script&lang=js&"
import style0 from "./SingleDraftActivitySlicePage1.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VChip,VCol,VDataTable,VDivider,VIcon,VRow,VToolbar,VToolbarTitle})
