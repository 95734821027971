<template>
  <AbstractSimpleShareCropping
    :assignedHectares="singleDraftActivitySlice.assignedHectares"
    :dynamicYield="singleDraftActivitySlice.summary.dynamicYield"
    :fixedSharecroppingPercent="
      singleDraftActivitySlice.fixedSharecroppingPercent
    "
    :isDraft="true"
    @rowSave="saveClick"
  />
</template>

<script>
import AbstractSimpleShareCropping from "../Abstract/AbstractSimpleShareCropping.vue";
import { mapGetters } from "vuex";
import { putRequest } from "../../managrx/serverCall";
export default {
  name: "SimpleShareCropping",
  components: {
    AbstractSimpleShareCropping,
  },
  methods: {
    async saveClick(payload) {
      let response = await putRequest(
        `/draft_activity_slice/${this.singleDraftActivitySlice.id}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave", {});
      }

      this.editMode = false;
    },
  },
  computed: {
    ...mapGetters(["singleDraftActivitySlice"]),
  },
};
</script>
