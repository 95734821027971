<template>
  <div>
    <AbstractNewVariableShareCropping
      :activitySliceId="draftActivitySliceId"
      :deleteAllButtonDisabled="isVariableShareCroppingRowsEmpty"
      @variableSharecroppingInterpolationRowSave="interpolationSave"
      @showVariableShareCroppingSchedule="showVariableShareCroppingSchedule"
      @deleteAllVariableShareCroppingSchedules="
        deleteAllVariableShareCroppingSchedules
      "
    />
  </div>
</template>
<script>
import { postRequest, deleteRequest } from "@/managrx/serverCall";
import AbstractNewVariableShareCropping from "../Abstract/AbstractNewVariableShareCropping.vue";
export default {
  name: "NewVariableShareCropping",
  components: {
    AbstractNewVariableShareCropping,
  },
  props: {
    draftActivitySliceId: Number,
    variableShareCroppingRows: Array,
  },

  methods: {
    async interpolationSave(payload) {
      let failedRequests = [];
      let successfulRequests = [];

      for (let i = 0; i < payload.length; i++) {
        let schedule = payload[i];
        schedule["draftActivitySliceId"] = this.draftActivitySliceId;

        let response = await postRequest(
          "/draft_variable_share_crop_schedule",
          schedule
        );

        if (!response.success) {
          failedRequests.push(response);
        } else {
          successfulRequests.push(response);
        }
      }

      this.$emit(
        "variableSharecroppingInterpolationRowSave",
        successfulRequests.map((i) => i.data)
      );
      console.log(successfulRequests);
      console.log(failedRequests);
    },

    async deleteAllVariableShareCroppingSchedules() {
      let failedRequests = [];

      for (
        let index = 0;
        index < this.variableShareCroppingRows.length;
        index++
      ) {
        const row = this.variableShareCroppingRows[index];
        const response = await deleteRequest(
          "/draft_variable_share_crop_schedule/" + row["id"]
        );
        if (response.status !== 200) {
          failedRequests.push(response);
        }
      }

      if (failedRequests.length > 0) {
        alert("Something went wrong please refresh (A delete request failed)");
      }

      this.$emit("variableSharecroppingInterpolationRowSave");
    },
    showVariableShareCroppingSchedule() {
      this.$emit("showVariableShareCroppingSchedule");
    },
  },
  computed: {
    isVariableShareCroppingRowsEmpty() {
      return this.variableShareCroppingRows.length == 0;
    },
  },
};
</script>