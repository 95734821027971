<template>
  <div>
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="orange darken-4 white--text px-2"
          @click.stop="boundsCheckDialog = true"
        >
          <v-icon> mdi-clipboard-text-clock </v-icon>
          <span class="px-2">Bounds Check </span>
        </v-btn>

        <v-dialog v-model="boundsCheckDialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5"> Bounds Check </v-card-title>

            <v-card-text>
              Ingrese las fechas en las que desea realizar un
              <strong>Bounds Check</strong> a nivel
              <strong>{{ checkLevel }}</strong>
            </v-card-text>

            <v-text-field
              label="Fecha de inicio"
              dense
              type="date"
              class="managrx-input ma-2 pa-4"
              v-model="startDate"
            ></v-text-field>

            <v-text-field
              label="Fecha de finalización"
              dense
              type="date"
              class="managrx-input ma-2 pa-4"
              v-model="endDate"
            ></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="boundsCheckDialog = false"
              >
                Cancelar
              </v-btn>

              <v-btn color="green darken-1" text @click="startBoundsCheck">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog
      v-if="!isLoading"
      v-model="boundsCheckResultDialog"
      class="pa-1"
      max-width="1010"
    >
      <v-card>
        <v-card-title class="text-h5"> Resultado Bounds Check </v-card-title>
        <v-card-subtitle>
          <span
            >Desde <strong>{{ startDate }}</strong> hasta
            <strong>{{ endDate }}</strong></span
          >
        </v-card-subtitle>
        <v-card-text>
          <v-alert
            v-if="!boundCheckFail && !isLoading"
            outlined
            type="success"
            class="pa-1"
            text
          >
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Todas las fechas de ingresos estan en rango'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Todas las fechas de siembra estan en rango'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Todas las fechas de cosecha estan en rango'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      'Todas las fechas de los costos asignados estan en rango'
                    "
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      'Todas las fechas de los costos por tonelada estan en rango'
                    "
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-alert>
          <v-alert
            v-else-if="boundCheckFail && !isLoading"
            outlined
            class="pa-1"
            type="warning"
            text
          >
            <span>
              Algunas de las fechas se encuentran fuera del rango para la
              campaña elegida
            </span>
            <v-list>
              <v-list-item v-if="!incomeDateOutOfBounds.length">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Todas las fechas de ingresos estan en rango'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else
                v-for="info in incomeDateOutOfBounds"
                class="pa-1"
                :key="
                  info['activity_slice_id'] +
                  getActivityIncomeParsedInformation(info)
                "
              >
                <v-list-item-icon>
                  <v-icon color="error"> mdi-close </v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <div
                    class="
                      ma-0
                      pa-0
                      high-light-on-focus
                      text-decoration-none text--secondary
                    "
                    style="word-break: break-all"
                  >
                    {{ getActivityIncomeParsedInformation(info) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="!sowDateOutOfBounds.length">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Todas las fechas de siembra estan en rango'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else
                v-for="info in sowDateOutOfBounds"
                class="pa-1"
                :key="
                  info['activity_slice_id'] +
                  getActivitySowParsedInformation(info)
                "
              >
                <v-list-item-icon>
                  <v-icon color="error"> mdi-close </v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <div
                    class="
                      ma-0
                      pa-0
                      high-light-on-focus
                      text-decoration-none text--secondary
                    "
                    style="word-break: break-all"
                  >
                    {{ getActivitySowParsedInformation(info) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="!harvestDateOutOfBounds.length">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Todas las fechas de cosecha estan en rango'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else
                v-for="info in harvestDateOutOfBounds"
                class="pa-1"
                :key="
                  info['activity_slice_id'] +
                  getActivityHarvestParsedInformation(info)
                "
              >
                <v-list-item-icon>
                  <v-icon color="error"> mdi-close </v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <div
                    class="
                      ma-0
                      pa-0
                      high-light-on-focus
                      text-decoration-none text--secondary
                    "
                    style="word-break: break-all"
                  >
                    {{ getActivityHarvestParsedInformation(info) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="!insuranceDateOutOfBounds.length">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Todas las fechas de seguros estan en rango'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else
                v-for="info in insuranceDateOutOfBounds"
                class="pa-1"
                :key="
                  info['activity_slice_id'] +
                  getActivityInsuranceParsedInformation(info)
                "
              >
                <v-list-item-icon>
                  <v-icon color="error"> mdi-close </v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <div
                    class="
                      ma-0
                      pa-0
                      high-light-on-focus
                      text-decoration-none text--secondary
                    "
                    style="word-break: break-all"
                  >
                    {{ getActivityInsuranceParsedInformation(info) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="!assignedOutOfBounds.length">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      'Todas las fechas de los costos asignados estan en rango'
                    "
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else
                v-for="info in assignedOutOfBounds"
                class="pa-1"
                :key="
                  info['activity_slice_id'] +
                  getActivityAssignedParsedInformation(info)
                "
              >
                <v-list-item-icon>
                  <v-icon color="error"> mdi-close </v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <div
                    class="
                      ma-0
                      pa-0
                      high-light-on-focus
                      text-decoration-none text--secondary
                    "
                    style="word-break: break-all"
                  >
                    {{ getActivityAssignedParsedInformation(info) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="!otherTonOutOfBounds.length">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      'Todas las fechas de los costos por tonelada estan en rango'
                    "
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else
                v-for="info in otherTonOutOfBounds"
                class="pa-1"
                :key="
                  info['activity_slice_id'] +
                  getActivityOTCParsedInformation(info)
                "
              >
                <v-list-item-icon>
                  <v-icon color="error"> mdi-close </v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <div
                    class="
                      ma-0
                      pa-0
                      high-light-on-focus
                      text-decoration-none text--secondary
                    "
                    style="word-break: break-all"
                  >
                    {{ getActivityOTCParsedInformation(info) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="boundsCheckResultDialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { postRequest } from "../../managrx/serverCall";
export default {
  name: "AbstractBoundsCheck",
  props: {
    isDraft: Boolean,
    id: Number,
    campaignStartDate: String,
    campaignEndDate: String,
    checkLevel: String,
    activitiesInfo: Array,
    activityName:String
  },
  data() {
    return {
      incomeDateOutOfBounds: [],
     
      boundsCheckResultDialog: false,
      boundsCheckDialog: false,
      startDate: this.campaignStartDate
        ? this.campaignStartDate
        : new Date().toISOString(),
      endDate: this.campaignEndDate
        ? this.campaignStartDate
        : new Date().toISOString(),
      sowDateOutOfBounds: [],
      harvestDateOutOfBounds: [],
      insuranceDateOutOfBounds: [],
      assignedOutOfBounds: [],
      otherTonOutOfBounds: [],
      isLoading: false,
    };
  },
  computed: {
    boundCheckFail() {
      if (
        this.incomeDateOutOfBounds.length ||
        this.sowDateOutOfBounds.length ||
        this.harvestDateOutOfBounds.length ||
        this.insuranceDateOutOfBounds.length ||
        this.assignedOutOfBounds.length ||
        this.otherTonOutOfBounds.length
      ) {
        return true;
      }
      return false;
    },
    endpointEnd() {
      return this.isDraft ? "/draft_activity_slice" : "/activity_slice";
    },
  },
  methods: {
    getActivityIncomeParsedInformation() {
      let activityName = this.activityName
      return activityName + " - " + "Fecha de INGRESO fuera de rango";
    },
    getActivitySowParsedInformation() {
      let activityName = this.activityName

      return activityName + " - " + "Fecha de SIEMBRA fuera de rango";
    },
    getActivityHarvestParsedInformation() {
      let activityName = this.activityName

      return activityName + " - " + "Fecha de COSECHA fuera de rango";
    },
    getActivityInsuranceParsedInformation() {
      let activityName = this.activityName

      return activityName + " - " + "Fecha de SEGURO fuera de rango";
    },
    getActivityAssignedParsedInformation(info) {
      console.log(info)
      let activityName = this.activityName
      return (
        activityName +
        " - " +
        info.costItemFamilyName +
        " - " +
        info.directSliceCostItemName +
        " con fecha de uso en " +
        info.dateUsed?.split(" ")[0]
      );
    },
    getActivityOTCParsedInformation(info) {
      console.log(info)
      let activityName = this.activityName

      return (
        activityName +
        " - " +
        info.otherTonCostItemName +
        " - " +
        "Fecha con uso en " +
        info.dateUsed?.split(" ")[0]
      );
    },
    parseBoundsCheckData(data) {
      console.log(data);
      this.incomeDateOutOfBounds = data.incomeDateOutOfBounds;
      this.sowDateOutOfBounds = data.sowDateOutOfBounds;
      this.harvestDateOutOfBounds = data.harvestDateOutOfBounds;
      this.insuranceDateOutOfBounds = data.insuranceDateOutOfBounds;
      this.assignedOutOfBounds = data.assignedDirectSlcOutOfBounds;
      this.otherTonOutOfBounds = data.otherTonItemsOutOfBounds;
    },
    async startBoundsCheck() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.isLoading = true;
      let endpoint;
      let result;

      let payload = {
        startDate: new Date(this.startDate).toISOString(),
        endDate: new Date(this.endDate).toISOString(),
        id: this.id,
      };

      endpoint = "/module/post/bounds_check" + this.endpointEnd;

      result = await postRequest(endpoint, payload);
      if (result.success) {
        this.parseBoundsCheckData(result.data);
        this.boundsCheckDialog = false;
        this.boundsCheckResultDialog = true;
      } else {
        this.$store.dispatch("setNewNotification", result);
      }
      this.$store.dispatch("setPageLoadingStatus", false);
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
