<template>
  <v-container fluid>
    <v-tabs v-model="tab" class="mb-2">
      <v-tab>Resumen General</v-tab>
      <v-tab @click="resetCartesianProduct">Metrics</v-tab>
      <v-tab>Cashflows</v-tab>
      <v-tab disabled>Eventos de Cosecha</v-tab>
      <v-tab>Globals</v-tab>

      <h2 class="mt-2 ml-auto grey--text d-flex subtitle-1 warning--text">
        {{ draftName }}
      </h2>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SingleDraftActivitySlicePage1
          :variableParticipationDate="variableParticipationDate"
          @refreshCashflow="refreshCashflow"
          @fluidClick="() => (fluid = !fluid)"
        />
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <AbstractCartesianProductTable
              :activityPrice="cartesianProductActivityPrice"
              :dynamicYield="cartesianProductDynamicYield"
              :saleTax="singleDraftActivitySlice.saleTax"
              :saleComission="singleDraftActivitySlice.saleComission"
              :saleHandOver="singleDraftActivitySlice.saleHandOver"
              :totalOtherTonCost="
                singleDraftActivitySlice.summary.otherTonCostTotalByHa
              "
              :nonAutofinanciableCosts="
                singleDraftActivitySlice.summary
                  .nonautofinanciableCostsByHectare
              "
              :freightPrice="singleDraftActivitySlice.freightCostTn"
              :technicalDirection="singleDraftActivitySlice.technicalDirection"
              :percentHc="singleDraftActivitySlice.harvestPercentCost"
              :maxHc="singleDraftActivitySlice.maxHarvestCost"
              :minHc="singleDraftActivitySlice.minHarvestCost"
              :fixedSharecroppingPercent="
                singleDraftActivitySlice.fixedSharecroppingPercent
              "
              :varaibleSharecroppingArray="
                singleDraftActivitySlice.variableShareCropSchedule
              "
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <DenseCashflowTable
              :rows="cashflowRows"
              :columns="cashflowColumns"
              :totalHaToolTip="activityTotalHaToolTip"
              :startDate="startDate"
              :endDate="endDate"
              :isResourceUtilization="false"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <AbstractDraftHarvestEvent
          :harvestEvents="singleDraftActivitySlice.harvestEvents"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :forwardYield="singleDraftActivitySlice.forwardYield"
          :dynamicYield="dynamicYield"
          @harvestComplete="harvestComplete"
          @saveNewHarvestEvent="saveNewHarvestEvent"
          @saveHarvestEventEdit="saveHarvestEventEdit"
          @deleteHarvestEvent="deleteHarvestEvent"
          @refreshCashflow="refreshCashflow"
        />
      </v-tab-item>
      <v-tab-item>
        <AbstractDraftGlobals
          :activityId="singleDraftActivitySlice.summary.activityId"
          :activityName="singleDraftActivitySlice.summary.activityName"
          :activities="activities"
          :vDataTableHeaders="vDataTableHeaders"
          :draftEngagementItems="selectableDraftEngagementItems"
          :draftActivitySliceId="editedDraftActivitySlice.id"
          :assignedHectares="editedDraftActivitySlice.assignedHectares"
          :activityPrice="editedDraftActivitySlice.activityPrice"
          :sowDate="editedDraftActivitySlice.sowDate"
          :harvestDate="editedDraftActivitySlice.harvestDate"
          :incomeDate="editedDraftActivitySlice.incomeDate"
          :forwardYield="editedDraftActivitySlice.forwardYield"
          :isFixedSharecroppingHandedOver="
            editedDraftActivitySlice.isFixedSharecroppingHandedOver
          "
          :isVariableSharecroppingHandedOver="
            editedDraftActivitySlice.isVariableSharecroppingHandedOver
          "
          :rentPercentApplication="
            editedDraftActivitySlice.rentPercentApplication
          "
          :insuranceCategories="singleDraftActivitySlice.insuranceCategories"
          :insuranceRate="singleDraftActivitySlice.insuranceRate"
          :insuranceMaterializationDate="
            singleDraftActivitySlice.insuranceMaterializationDate
          "
          :draftActivitySlice="editedDraftActivitySlice"
          :grainQualityFactor="editedDraftActivitySlice.grainQualityFactor"
          @saveEditsToDraftActivitySlice="saveEditsToDraftActivitySlice"
          @deleteSingleDraftActivitySlice="deleteSingleDraftActivitySlice"
          @saveDraftActivitySlice="saveDraftActivitySlice"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<style>
.v-application .display-1 {
  font-size: 28px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { putRequest, deleteRequest, postRequest } from "../managrx/serverCall";
import { unPackAndProcessCashflowKeys } from "@/utils";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import AbstractDraftGlobals from "../components/Abstract/AbstractDraftGlobals.vue";
import AbstractDraftHarvestEvent from "../components/Abstract/AbstractDraftHarvestEvent.vue";
import SingleDraftActivitySlicePage1 from "../components/Draft/SingleDraftActivitySlicePage1.vue";
import AbstractCartesianProductTable from "../components/Abstract/AbstractCartesianProductTable.vue";
import { activityLevelTotalHaToolTip } from "../managrx/constants.js";

export default {
  name: "SingleDraftActivitySlice",
  components: {
    AbstractDraftHarvestEvent,
    DenseCashflowTable,
    AbstractDraftGlobals,
    SingleDraftActivitySlicePage1,
    AbstractCartesianProductTable,
  },
  methods: {
    harvestComplete() {
      let notif = {
        success: true,
        data: {
          type: "success",
          text: "Cosecha completada con exito.",
        },
      };
      this.$store.dispatch("setNewNotification", notif);
    },
    async saveNewHarvestEvent(payload) {
      payload.draftActivitySliceId = this.singleDraftActivitySlice?.id;
      let endpoint = "/draft_harvest_event/";
      let result = await postRequest(endpoint, payload);
      if (!result.success) {
        this.errorHandler(result);
      } else {
        this.$store.dispatch("updateDraftSASliceCache", "harvestEvents");
        this.$store.dispatch("updateDraftSASliceCache", "summary");
        this.$emit("refreshCashflow");
      }
    },
    async saveHarvestEventEdit(payload) {
      let endpoint = "/draft_harvest_event/" + payload?.id;
      let result = await putRequest(endpoint, payload?.row);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        this.$store.dispatch("updateDraftSASliceCache", "harvestEvents");
        this.$store.dispatch("updateDraftSASliceCache", "summary");
        this.$emit("refreshCashflow");
      }
    },
    async deleteHarvestEvent(id) {
      let endpoint = "/draft_harvest_event/" + id;
      let result = await deleteRequest(endpoint);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        this.$store.dispatch("updateDraftSASliceCache", "harvestEvents");
        this.$store.dispatch("updateDraftSASliceCache", "summary");
        this.$emit("refreshCashflow");
      }
    },
    resetCartesianProduct() {
      this.cartesianProductDynamicYield =
        this.singleDraftActivitySlice?.summary?.dynamicYield;
      this.cartesianProductActivityPrice =
        this.singleDraftActivitySlice.activityPrice;
    },
    async saveDraftActivitySlice(payload) {
      let draftActivitySliceId = payload?.draftActivitySliceId;
      let activityId = payload?.activityId;
      let draftEngagementId = payload?.draftEngagementId ?? null;
      let response;
      // if (draftEngagementId) {
      response = await postRequest(
        `/module/post/copy/draft_activity_slice/${draftActivitySliceId}`,
        { newDraftEngagementId: draftEngagementId, activityId: activityId }
      );
      // } else {
      //   response = await postRequest(
      //     `/module/post/copy/draft_activity_slice/${draftActivitySliceId}`
      //   );
      // }
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        let result = response?.data;
        if (result.success) {
          let newDraftActivitySliceId = result.draftActivitySliceId;
          this.$router.push({
            name: "SingleDraftActivitySlice",
            params: { id: newDraftActivitySliceId },
          });
        }
      }
    },
    async deleteSingleDraftActivitySlice(payload) {
      let draftActivitySliceId = payload;
      let response = await deleteRequest(
        `/module/delete/draft_activity_slice/${draftActivitySliceId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.exitRoute();
      }
    },
    async saveEditsToDraftActivitySlice(payload) {
      const draftActivitySliceId = payload.draftActivitySliceId;
      delete payload.draftActivitySliceId;
      let response = await putRequest(
        `/draft_activity_slice/${draftActivitySliceId}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      }
      this.refreshRoute();
      this.$router.go();
    },
    refreshRoute() {
      this.$store.dispatch(
        "getSingleDraftActivitySlice",
        parseInt(this.$route.params.id)
      );
    },
    refreshCashflow() {
      this.$store.dispatch("getDraftActivitySliceCashflow", {
        activitySliceId: this.$route.params.id,
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
      });
    },
    exitRoute() {
      let draftEngagementId = this.singleDraftEngagement?.draftEngagementId;
      this.$router.push({
        name: "SingleDraftEngagement",
        params: { id: draftEngagementId },
      });
    },
  },
  data() {
    return {
      tab: null,
      fluid: true,
      editedDraftActivitySlice: {},

      cartesianProductDynamicYield: 0,
      cartesianProductActivityPrice: 0,
    };
  },
  beforeCreate() {
    this.$store.dispatch("clearSingleDraftActivitySlice");
    this.$store.dispatch("getDraftEngagements");
  },
  beforeDestroy() {
    this.$store.dispatch("clearSingleDraftActivitySlice");
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(
        "getSingleDraftActivitySlice",
        parseInt(this.$route.params.id)
      ),
      this.$store.dispatch("getAllCostItems"),
      this.$store.dispatch("fetchFinancialPolicies"),
      this.$store.dispatch("getActivities"),
      this.$store.dispatch("fetchSliceCostInputPeriods"),
      this.$store.dispatch("fetchInterestRates"),
      this.$store.dispatch("fetchOtherTonCostItems"),
    ]);
    this.editedDraftActivitySlice = {
      ...this.singleDraftActivitySlice,
      assignedHectares: this.singleDraftActivitySlice.assignedHectares,
      sowDate: this.singleDraftActivitySlice?.sowDate?.split("T")[0],
      incomeDate: this.singleDraftActivitySlice?.incomeDate?.split("T")[0],
      harvestDate: this.singleDraftActivitySlice?.harvestDate?.split("T")[0],
    };

    document.title = this.singleDraftActivitySlice?.activity?.name;
    await this.$store.dispatch("getDraftActivitySliceCashflow", {
      activitySliceId: this.$route.params?.id,
      startYear: this.cashflowStartYear,
      startMonth: this.cashflowStartMonth,
      endYear: this.cashflowEndYear,
      endMonth: this.cashflowEndMonth,
    });
  },
  computed: {
    ...mapGetters([
      "activities",
      "singleDraftActivitySliceCashflow",
      "singleDraftActivitySlice",
      "sliceProductsList",
      "isPageLoading",
      "draftEngagements",
      "cartesianProductTerms",
    ]),
    dynamicYield() {
      return this.singleDraftActivitySlice?.summary?.dynamicYield;
    },
    activityTotalHaToolTip() {
      return activityLevelTotalHaToolTip;
    },
    variableParticipationDate() {
      return this.singleDraftActivitySlice?.engagement
        ?.variableParticipationDate;
    },
    startDate() {
      let date = new Date(this.variableParticipationDate);
      date.setMonth(date.getMonth() - 11);
      return date.toISOString();
    },
    endDate() {
      return this.variableParticipationDate;
    },
    cashflowEndMonth() {
      let date = new Date(this.variableParticipationDate);

      return date.getMonth() + 1;
    },
    cashflowStartMonth() {
      let date = new Date(this.variableParticipationDate);

      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date = new Date(this.variableParticipationDate);

      return date.getFullYear();
    },
    cashflowStartYear() {
      let date = new Date(this.variableParticipationDate);

      return date.getFullYear() - 1;
    },
    singleDraftEngagement() {
      return this.draftEngagementItems.find(
        (el) =>
          el?.draftEngagementId ==
          this.singleDraftActivitySlice?.draftEngagementId
      );
    },
    draftName() {
      return this.singleDraftEngagement?.draftName;
    },
    term1() {
      return this.cartesianProductTerms?.term1;
    },

    term2() {
      return this.cartesianProductTerms?.term2;
    },

    term3() {
      return this.cartesianProductTerms?.term3;
    },

    term4() {
      return this.cartesianProductTerms?.term4;
    },
    term5() {
      return this.cartesianProductTerms?.term5;
    },
    isHarvestCostDependent() {
      return this.cartesianProductTerms?.isHarvestCostDependent ? true : false;
    },
    cashflowRows() {
      return this.singleDraftActivitySliceCashflow?.rows;
    },
    cashflowColumns() {
      return unPackAndProcessCashflowKeys(
        this.singleDraftActivitySliceCashflow?.keys
      );
    },
    selectableDraftEngagementItems() {
      return this.draftEngagementItems
    },
    draftEngagementItems() {
      return this.draftEngagements.items.map((d) => {
        return {
          ...d,
          createdAt: new Date(d.createdAt)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
        };
      });
    },
    vDataTableHeaders() {
      return [
        { text: "Nombre del Borrador", value: "draftName" },
        {
          text: "Region",
          value: "region",
        },
        {
          text: "Establecimiento",
          value: "establishmentName",
        },
        {
          text: "Has. Físicas",
          value: "totalHectares",
        },
        {
          text: "Creado",
          value: "createdAt",
        },
        {
          text: "Arrendamientos qq/ha",
          value: "rentQqHa",
        },
        {
          text: "Precio en adelanto Arrendamientos",
          value: "rentQqActivityPrice",
        },
      ];
    },
  },
};
</script>
