<template>
  <AbstractHarvestCostInput
    :assignedHectares="singleDraftActivitySlice.assignedHectares"
    :minHarvestCost="singleDraftActivitySlice.minHarvestCost"
    :maxHarvestCost="singleDraftActivitySlice.maxHarvestCost"
    :harvestPercentCost="singleDraftActivitySlice.harvestPercentCost"
    :dynamicYield="singleDraftActivitySlice.summary.dynamicYield"
    :forwardPrice="forwardPrice"
    :isDraft="true"
    @saveClick="saveClick"
  />
</template>

<script>
import { mapGetters } from "vuex";
import AbstractHarvestCostInput from "../Abstract/AbstractHarvestCostInput.vue";
import { putRequest } from "../../managrx/serverCall";
export default {
  name: "HarvestCostInput",
  components: { AbstractHarvestCostInput },
  methods: {
    async saveClick(payload) {
      try {
        let response = await putRequest(
          `/draft_activity_slice/${this.singleDraftActivitySlice.id}`,
          payload
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          this.$emit("putResponseSuccess", {});
        }
        this.editMode = false;
      } catch (error) {
        let notification = {
          type: "error",
          text: `${error?.response?.data?.error}`,
        };
        this.$store.dispatch("setNewNotification", notification);
      }
    },
  },
  computed: {
    ...mapGetters(["singleDraftActivitySlice"]),
    forwardPrice() {
      return this.singleDraftActivitySlice?.summary?.forwardPrice;
    },
  },
};
</script>
