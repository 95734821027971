<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="overline mt-2 ml-4 grey--text d-flex">
          Draft Activity Slice
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="ml-4 mb-5">
          <div class="d-flex align-center mb-0 pb-0">
            <h3 class="primary--text" style="font-weight: 400">
              Editar Activity Slice

            </h3>
            <v-btn
            v-if="modifiedValuesNotSaved"
            small
            depressed
            color="info"
            class="ms-5"
            @click="resetValues"
            >
            <v-icon> mdi-restart</v-icon>
          </v-btn>
        </div>
            <div class="ma-0 pa-0" v-if="modifiedValuesNotSaved">
            <span
              class="font-weight-normal font-italic text--secondary caption text--muted"
              >** Hay valores modificados que todavia no fueron guardados.</span
            >
          </div>
        </div>
        <v-form ref="form" class="d-flex flex-column ml-4 mt-4 pt-4">
          <v-text-field
            label="Hectáreas asignadas"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            :rules="assignedHectaresRules"
            v-model.number="singleActivitySlice.assignedHectares"
          ></v-text-field>
          <v-text-field
            label="Valor final de actividad"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            :rules="activityPriceRules"
            v-model.number="singleActivitySlice.activityPrice"
          ></v-text-field>

          <v-text-field
            label="Mes Siembra"
            dense
            type="date"
            :rules="sowDateRules"
            class="managrx-input ma-2 pb-2"
            v-model="singleActivitySlice.sowDate"
          ></v-text-field>

          <v-text-field
            label="Mes Cosecha"
            dense
            type="date"
            :rules="harvestDateRules"
            class="managrx-input ma-2 pb-2"
            v-model="singleActivitySlice.harvestDate"
          ></v-text-field>

          <v-text-field
            label="Mes Ingreso"
            dense
            type="date"
            :rules="incomeDateRules"
            class="managrx-input ma-2 pb-2"
            v-model="singleActivitySlice.incomeDate"
          ></v-text-field>

          <v-text-field
            label="Rinde esperado"
            dense
            type="number"
            :rules="forwardYieldRules"
            class="managrx-input ma-2 pb-2"
            v-model.number="singleActivitySlice.forwardYield"
          ></v-text-field>

          <v-select
            label="Aparcería Fija"
            :items="[
              { name: 'Entrega KG', value: true },
              { name: 'No entrega KG', value: false },
            ]"
            item-text="name"
            item-value="value"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            v-model="singleActivitySlice.isFixedSharecroppingHandedOver"
          ></v-select>
          <v-select
            label="Aparcería Variable"
            :items="[
              { name: 'Entrega KG', value: true },
              { name: 'No entrega KG', value: false },
            ]"
            item-text="name"
            item-value="value"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            v-model="singleActivitySlice.isVariableSharecroppingHandedOver"
          ></v-select>
          <v-text-field
            label="Arrendamiento (0-100)%"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            :rules="rentPercentApplicationRules"
            v-model.number="singleActivitySlice.rentPercentApplication"
          ></v-text-field>
          <v-select
            label="Seguros"
            :items="[
              'Semillas',
              'Herbicidas',
              'Insecticidas',
              'Fertilizantes',
              'Fungicidas',
              'Labranza',
              'Aplicaciones',
              'Seguimiento y Control',
              'Arrendamientos',
              'Varios',
            ]"
            clearable
            v-model="singleActivitySlice.insuranceCategories"
            dense
            class="managrx-input ma-2 pb-2"
            multiple
          >
          </v-select>
          <v-text-field
            label="Seguro (0-100)%"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            v-model.number="singleActivitySlice.insuranceRate"
          ></v-text-field>
          <v-text-field
            label="Mes de materialización del seguro"
            dense
            type="date"
            class="managrx-input ma-2 pb-2"
            v-model="singleActivitySlice.insuranceMaterializationDate"
          ></v-text-field>
          <v-text-field
            label="Calidad Grano (0-100)%"
            dense
            type="number"
            class="managrx-input ma-2 pb-2"
            :rules="grainQualityFactorRules"
            v-model.number="singleActivitySlice.grainQualityFactor"
          ></v-text-field>
          <div class="d-flex flex-row align-center justify-center mt-4 pt-4">
            <v-btn
              class="mb-1 me-4"
              color="primary white--text"
              @click="saveEditsToActivitySlice"
            >
              <v-icon> mdi-floppy </v-icon></v-btn
            >
            <v-btn
              color="primary white--text mb-1 me-4"
              @click.stop="quickCopyDialog = true"
            >
              <v-icon> mdi-content-copy </v-icon>
              <span class="px-2">Quick Copy</span>
            </v-btn>

            <v-dialog v-model="quickCopyDialog" max-width="505">
              <v-card>
                <v-card-title class="text-h5">
                  ¿Esta seguro que desea copiar la Actividad de este Borrador?
                </v-card-title>

                <v-card-text>
                  <ul>
                    <!-- <li>
                      Al apretar <strong>Copiar Aqui</strong> se creara una
                      copia <strong>exacta</strong> de todos los datos
                      ingresados para el borrador de esta Actividad dentro del
                      mismo Negocio Borrador.
                    </li>
                    <br /> -->
                    <v-radio-group v-model="quickCopySameActivity">
                      <v-radio
                        :label="activityName"
                        @click="quickCopySelectedActivityId = activityId"
                        :value="true"
                      ></v-radio>
                      <div class="d-flex flex-row align-center mt-2">
                        <v-radio
                          label="Otra: "
                          class="pe-3"
                          :value="false"
                          @click="quickCopySelectedActivityId = defaultSelect"
                        ></v-radio>
                        <v-select
                          v-if="!quickCopySameActivity"
                          :items="availableActivities"
                          v-model="quickCopySelectedActivityId"
                          item-text="name"
                          item-value="id"
                          select
                          dense
                          label="Actividad"
                        >
                        </v-select>

                        <v-select
                          v-else
                          readonly
                          label="Actividad"
                          disabled
                          dense
                        ></v-select>
                      </div>
                    </v-radio-group>
                    <li v-if="quickCopySelectedActivityId != null">
                      Al apretar <strong> SIGUIENTE</strong> se creara una copia
                      <strong>exacta</strong> de todos los datos ingresados para
                      esta Actividad Borrador dentro de
                      <strong>otro o el mismo</strong> Negocio Borrador usando
                      <strong> {{ selectedQuickCopyActivityName }}. </strong>
                      <br />
                      En el próximo paso eligiras el destino de este activity
                      slice.
                    </li>
                    <li v-else>
                      <strong> Selecciona una actividad para continuar </strong>
                    </li>
                  </ul>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="quickCopyDialog = false"
                  >
                    Cancelar
                  </v-btn>

                  <!-- <v-btn
                    color="green darken-1"
                    text
                    @click="saveDraftActivitySliceHere"
                  >
                    Copiar Aquí
                  </v-btn> -->
                  <v-btn
                    color="green darken-1"
                    text
                    :disabled="quickCopySelectedActivityId == null"
                    @click="showDraftEngagementsOptions"
                  >
                    Siguente
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="pickDraftEngagementDialog" max-width="750">
              <v-card>
                <v-card-title class="text-h5">
                  Elija el Negocio Borrador al cual desea copiar esta Actividad
                </v-card-title>

                <v-card-text class="overflow-auto">
                  <v-data-table
                    class="draft-engagement-btn"
                    dense
                    :headers="vDataTableHeaders"
                    :items="draftEngagementItems"
                    item-key="draftEngagementId"
                    show-select
                    single-select
                    v-model="selected"
                    hide-default-footer
                    disable-pagination
                    ><template v-slot:[`header.data-table-select`]></template
                  ></v-data-table>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="quickCopyDialog = true;pickDraftEngagementDialog = false;"
                  >
                    Atras
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="pickDraftEngagementDialog = false"
                  >
                    Cancelar
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="saveDraftActivitySliceWithOtherDraftEngagement"
                  >
                    Copiar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <AbstractBoundsCheck
              class="mb-1 me-4"
              :id="draftActivitySliceId"
              :activityName="draftActivitySlice.activity.name"
              :checkLevel="'Actividad'"
              :isDraft="true"
            />

            <v-btn color="red white--text mb-1 " @click.stop="dialog = true">
              <v-icon> mdi-delete </v-icon>
            </v-btn>

            <v-dialog v-model="dialog" max-width="505">
              <v-card>
                <v-card-title class="text-h5">
                  ¿Esta seguro que desea eliminar el Borrador de esta Actividad?
                </v-card-title>

                <v-card-text>
                  Al apretar Eliminar se borraran todos los datos ingresados
                  para el borrador de esta actividad y no podran ser
                  recuperados.
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="green darken-1" text @click="dialog = false">
                    Cancelar
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="deleteSingleDraftActivitySlice"
                  >
                    Eliminar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AbstractBoundsCheck from "./AbstractBoundsCheck.vue";
import { DRAFT_GLOBALS_RULES } from "../../managrx/formsFieldsRules";
import { validateForm } from "../../managrx/utils";
export default {
  name: "AbstractDraftGlobals",
  components: {
    AbstractBoundsCheck,
  },
  props: {
    activities: Array,
    activityId: Number,
    activityName: String,
    vDataTableHeaders: Array,
    draftEngagementItems: Array,
    draftActivitySliceId: Number,
    assignedHectares: Number,
    activityPrice: Number,
    sowDate: String,
    harvestDate: String,
    incomeDate: String,
    forwardYield: Number,
    isFixedSharecroppingHandedOver: Boolean,
    isVariableSharecroppingHandedOver: Boolean,
    rentPercentApplication: Number,
    grainQualityFactor: Number,
    insuranceCategories: Array,
    insuranceRate: Number,
    insuranceMaterializationDate: String,
    draftActivitySlice: Object,
  },
  methods: {
    // saveDraftActivitySliceHere() {
    //   let draftActivitySliceId = this.singleActivitySlice.id;
    //   this.quickCopyDialog = false;
    //   let payload = { draftActivitySliceId };
    //   this.$emit("saveDraftActivitySlice", payload);
    // },
    showDraftEngagementsOptions() {
      this.quickCopyDialog = false;
      this.pickDraftEngagementDialog = true;
    },
    saveDraftActivitySliceWithOtherDraftEngagement() {
      let draftActivitySliceId = this.singleActivitySlice.id;
      let draftEngagementId = this.selected?.[0]?.draftEngagementId;
      let payload = {
        activityId: this.quickCopySelectedActivityId,
        draftActivitySliceId: draftActivitySliceId,
        draftEngagementId: draftEngagementId,
      };
      this.$emit("saveDraftActivitySlice", payload);
      this.quickCopySelectedActivityId = this.activityId;
    },
    deleteSingleDraftActivitySlice() {
      let draftActivitySliceId = this.singleActivitySlice.id;
      this.dialog = false;
      let payload = draftActivitySliceId;
      this.$emit("deleteSingleDraftActivitySlice", payload);
    },
    saveEditsToActivitySlice() {
      if (this.$refs.form.validate()) {
        let payload = {
          draftActivitySliceId: this.singleActivitySlice.id,
          grainQualityFactor: this.grainQualityFactorAsDecimal,
          insuranceRate: this.insuranceRateAsDecimal,
          insuranceCategories: this.parsedInsuranceCategories,
          rentPercentApplication: this.rentPercentApplicationAsDecimal,
        };
        let keys = [
          "assignedHectares",
          "activityPrice",
          "sowDate",
          "harvestDate",
          "incomeDate",
          "forwardYield",
          "isFixedSharecroppingHandedOver",
          "isVariableSharecroppingHandedOver",
          "insuranceMaterializationDate",
        ];

        for (let i = 0; i < keys.length; i++) {
          payload[keys[i]] = this.singleActivitySlice[keys[i]];

          if (keys[i].includes("Date")) {
            payload[keys[i]] = new Date(payload[keys[i]]).toISOString();
          }
        }

        this.$emit("saveEditsToDraftActivitySlice", payload);
      }
    },
    resetValues() {
      this.$refs.form.resetValidation();
      this.singleActivitySlice = {
        id: this.draftActivitySliceId,
        assignedHectares: this.assignedHectares,
        activityPrice: this.activityPrice,
        sowDate: this.sowDate?.slice(0, 10),
        harvestDate: this.harvestDate?.slice(0, 10),
        incomeDate: this.incomeDate?.slice(0, 10),
        forwardYield: this.forwardYield,
        isFixedSharecroppingHandedOver: this.isFixedSharecroppingHandedOver,
        isVariableSharecroppingHandedOver:
          this.isVariableSharecroppingHandedOver,
        rentPercentApplication: this.rentPercentApplicationAsInt,
        grainQualityFactor: this.grainQualityFactorAsInt,
        insuranceCategories: this.insuranceCategories,
        insuranceRate: this.insuranceRateAsInt,
        insuranceMaterializationDate: this.insuranceMaterializationDate?.slice(
          0,
          10
        ),
      };
    },
  },
  computed: {
    selectedQuickCopyActivityName() {
      if (this.quickCopySameActivity) {
        return this.activityName;
      } else {
        return this.activities.find(
          (el) => el?.id == this.quickCopySelectedActivityId
        )?.name;
      }
    },
    defaultSelect() {
      let defaultValue = this.availableActivities?.[0]?.id;
      if (defaultValue) {
        return defaultValue;
      } else {
        return 1;
      }
    },
    availableActivities() {
      return this.activities.filter((el) => el.id != this.activityId);
    },
    parsedInsuranceCategories() {
      return this.singleActivitySlice.insuranceCategories?.length
        ? this.singleActivitySlice.insuranceCategories?.join(",")
        : null;
    },
    insuranceRateAsInt() {
      return this.insuranceRate * 100;
    },
    insuranceRateAsDecimal() {
      return this.singleActivitySlice.insuranceRate / 100;
    },
    grainQualityFactorAsInt() {
      return this.grainQualityFactor * 100;
    },
    grainQualityFactorAsDecimal() {
      return this.singleActivitySlice.grainQualityFactor / 100;
    },
    rentPercentApplicationAsInt() {
      return this.rentPercentApplication * 100;
    },
    rentPercentApplicationAsDecimal() {
      return this.singleActivitySlice.rentPercentApplication / 100;
    },
    modifiedValuesNotSaved() {
      let orginalSingleActivitySlice = {
        id: this.draftActivitySliceId,
        assignedHectares: this.assignedHectares,
        activityPrice: this.activityPrice,
        sowDate: this.sowDate?.slice(0, 10),
        harvestDate: this.harvestDate?.slice(0, 10),
        incomeDate: this.incomeDate?.slice(0, 10),
        forwardYield: this.forwardYield,
        isFixedSharecroppingHandedOver: this.isFixedSharecroppingHandedOver,
        isVariableSharecroppingHandedOver:
          this.isVariableSharecroppingHandedOver,
        rentPercentApplication: this.rentPercentApplicationAsInt,
        grainQualityFactor: this.grainQualityFactorAsInt,
        insuranceCategories: this.insuranceCategories,
        insuranceRate: this.insuranceRateAsInt,
        insuranceMaterializationDate: this.insuranceMaterializationDate?.slice(
          0,
          10
        ),
      };
      let singleActivitySliceKeys = Object.keys(this.singleActivitySlice);
      let modifiedValues = singleActivitySliceKeys.map(
        (key) =>
          this.singleActivitySlice[key] == orginalSingleActivitySlice[key]
      );
      if (modifiedValues.every((v) => v === true)) {
        return false;
      }
      return true;
    },
    assignedHectaresRules() {
      if (
        !this.singleActivitySlice.assignedHectares &&
        this.singleActivitySlice.assignedHectares != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.assignedHectares;
      let detail = validateForm(
        this.singleActivitySlice.assignedHectares,
        fieldRules
      );
      return detail;
    },
    activityPriceRules() {
      if (
        !this.singleActivitySlice.activityPrice &&
        this.singleActivitySlice.activityPrice != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.activityPrice;
      let detail = validateForm(
        this.singleActivitySlice.activityPrice,
        fieldRules
      );
      return detail;
    },
    sowDateRules() {
      if (
        !this.singleActivitySlice.sowDate &&
        this.singleActivitySlice.sowDate != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.sowDate;
      let detail = validateForm(this.singleActivitySlice.sowDate, fieldRules);
      return detail;
    },
    harvestDateRules() {
      if (
        !this.singleActivitySlice.harvestDate &&
        this.singleActivitySlice.harvestDate != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.harvestDate;
      let detail = validateForm(
        this.singleActivitySlice.harvestDate,
        fieldRules
      );
      return detail;
    },
    incomeDateRules() {
      if (
        !this.singleActivitySlice.incomeDate &&
        this.singleActivitySlice.incomeDate != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.incomeDate;
      let detail = validateForm(
        this.singleActivitySlice.incomeDate,
        fieldRules
      );
      return detail;
    },
    forwardYieldRules() {
      if (
        !this.singleActivitySlice.forwardYield &&
        this.singleActivitySlice.forwardYield != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.forwardYield;
      let detail = validateForm(
        this.singleActivitySlice.forwardYield,
        fieldRules
      );
      return detail;
    },
    rentPercentApplicationRules() {
      if (
        !this.singleActivitySlice.rentPercentApplication &&
        this.singleActivitySlice.rentPercentApplication != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.rentPercentApplication;
      let detail = validateForm(
        this.singleActivitySlice.rentPercentApplication,
        fieldRules
      );
      return detail;
    },

    grainQualityFactorRules() {
      if (
        !this.singleActivitySlice.grainQualityFactor &&
        this.singleActivitySlice.grainQualityFactor != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.grainQualityFactor;
      let detail = validateForm(
        this.singleActivitySlice.grainQualityFactor,
        fieldRules
      );
      return detail;
    },
  },
  mounted() {
    this.quickCopySelectedActivityId = this.activityId;
    this.singleActivitySlice = {
      id: this.draftActivitySliceId,
      assignedHectares: this.assignedHectares,
      activityPrice: this.activityPrice,
      sowDate: this.sowDate?.slice(0, 10),
      harvestDate: this.harvestDate?.slice(0, 10),
      incomeDate: this.incomeDate?.slice(0, 10),
      forwardYield: this.forwardYield,
      isFixedSharecroppingHandedOver: this.isFixedSharecroppingHandedOver,
      isVariableSharecroppingHandedOver: this.isVariableSharecroppingHandedOver,
      rentPercentApplication: this.rentPercentApplicationAsInt,
      grainQualityFactor: this.grainQualityFactorAsInt,
      insuranceCategories: this.insuranceCategories,
      insuranceRate: this.insuranceRateAsInt,
      insuranceMaterializationDate: this.insuranceMaterializationDate?.slice(
        0,
        10
      ),
    };
  },
  data() {
    return {
      selected: [],
      quickCopySameActivity: true,
      quickCopySelectedActivityId: this.activityId,
      quickCopyDialog: false,
      pickDraftEngagementDialog: false,
      dialog: false,
      singleActivitySlice: {
        id: this.draftActivitySliceId,
        assignedHectares: this.assignedHectares,
        activityPrice: this.activityPrice,
        sowDate: this.sowDate?.slice(0, 10),
        harvestDate: this.harvestDate?.slice(0, 10),
        incomeDate: this.incomeDate?.slice(0, 10),
        forwardYield: this.forwardYield,
        isFixedSharecroppingHandedOver: this.isFixedSharecroppingHandedOver,
        isVariableSharecroppingHandedOver:
          this.isVariableSharecroppingHandedOver,
        rentPercentApplication: this.rentPercentApplicationAsInt,
        grainQualityFactor: this.grainQualityFactorAsInt,
        insuranceCategories: this.insuranceCategories,
        insuranceRate: this.insuranceRateAsInt,
        insuranceMaterializationDate: this.insuranceMaterializationDate?.slice(
          0,
          10
        ),
      },
    };
  },
};
</script>
