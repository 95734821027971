<template>
  <v-form ref="form" class="pr-2 pl-2">
    <div class="d-flex align-start">
      <v-text-field
        label="Familia"
        outlined
        dense
        filled
        class="managrx-input mr-2"
        :value="costParams.costItemFamilyName"
        readonly
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        outlined
        dense
        label="Fecha de uso"
        v-model="startDate"
        type="date"
      ></v-text-field>
      <v-autocomplete
        ref="mainSelect"
        @change="changeSelect"
        open-on-clear
        :items="directSliceCostItemsOptions"
        return-object
        item-text="value"
        item-value="idx"
        label="Producto"
        :rules="required"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-autocomplete>
      <v-text-field
        :label="'Precio por Ha.'"
        outlined
        dense
        type="number"
        v-model.number="costParams.priceHa"
        class="mb-0 pb-0 mr-2"
        :rules="priceRules"
      ></v-text-field>
      <v-text-field
        :label="'Costo Total'"
        outlined
        dense
        type="number"
        :value="totalCost"
        class="mb-0 pb-0 mr-2"
        :rules="priceRules"
        readonly
        filled
      ></v-text-field>

      <v-text-field
        label="Meses a Considerar"
        outlined
        dense
        :rules="monthConsiderationRules"
        class="managrx-input mr-2"
        type="number"
        v-model.number="costParams.monthsConsideration"
      >
      </v-text-field>
      <v-btn
        depressed
        color="primary white--text"
        class="mr-2 mb-7"
        @click="saveClick"
        :disabled="!deleteAllButtonDisabled"
      >
        <v-icon> mdi-floppy </v-icon>
      </v-btn>
      <v-btn depressed color="info" class="mr-2 mb-7" @click="resetForm">
        <v-icon> mdi-restart </v-icon>
      </v-btn>
      <v-btn
        depressed
        color="warning"
        class="mr-2 mb-7"
        @click="emitShowSandGCostRows"
        :disabled="deleteAllButtonDisabled"
      >
        <v-icon> mdi-eye-outline </v-icon>
      </v-btn>
      <v-btn
        depressed
        color="error"
        class="mr-2 mb-7"
        @click="emitDeleteAllSandGCostRows"
        :disabled="deleteAllButtonDisabled"
      >
        <v-icon> mdi-trash-can-outline </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { NEW_DRAFT_SANDG_FORM_RULES } from "../../managrx/formsFieldsRules";
import { getMonthRange, roundToX, validateForm } from "../../managrx/utils";

export default {
  name: "AbstractDraftNewSandGCostInput",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    harvestDate: String,
    directSliceCostItemsOptions: Array,
    deleteAllButtonDisabled: Boolean,
  },
  computed: {
    totalCost() {
      return roundToX(this.costParams?.priceHa * this.assignedHectares, 0);
    },
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    priceRules() {
      if (!this.costParams.priceHa && this.costParams.priceHa != 0) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_SANDG_FORM_RULES?.priceHa;
      let detail = validateForm(this.costParams.priceHa, fieldRules);
      return detail;
    },
    monthConsiderationRules() {
      if (
        !this.costParams.monthsConsideration &&
        this.costParams.monthsConsideration != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_SANDG_FORM_RULES?.monthsConsideration;
      let detail = validateForm(
        this.costParams.monthsConsideration,
        fieldRules
      );
      return detail;
    },
  },
  methods: {
    sendNotification(type, text) {
      let notification = {
        success: false,
        data: {
          type: type,
          text: text,
        },
      };
      this.$emit("sendNotification", notification);
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        let monthsConsiderationInteger = parseInt(
          this.costParams?.monthsConsideration
        );
        let sd = new Date(this.startDate);
        // Get timezone difference
        let minutesOffSet = sd.getTimezoneOffset();

        // Add timezone difference to get the correct starting date
        sd.setMinutes(sd.getMinutes() + minutesOffSet);

        let d1 = getMonthRange(monthsConsiderationInteger, sd);
        let itemsToInsert = [];
        for (let i = 0; i < d1.length; i++) {
          const d = d1[i];

          let item = {
            draftActivitySliceId: this.activitySliceId,
            directSliceCostItemId: this.costParams?.directSliceCostItemId,
            costInputPeriodId: 1, // (Sin periodo)
            unitPrice: this.costParams.priceHa,
            unitsPerHectare: 1,
            percentApplication: 1 / monthsConsiderationInteger,
            dateUsed: new Date(d).toISOString(),
            datePaid: new Date(d).toISOString(),
            paymentPolicyId: null,
            interestRateId: null,
          };

          itemsToInsert.push(item);
        }
        this.$emit("saveEditsToDraftActivitySlice", itemsToInsert);
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    changeSelect(item) {
      this.costParams.directSliceCostItemId = item.id;
      this.costParams.costItemFamilyName = item.costItemFamilyName;
      this.costParams.unitMeasure = item.unitMeasure;
    },
    emitShowSandGCostRows() {
      this.$emit("showSandGCostRows");
    },
    emitDeleteAllSandGCostRows() {
      this.$emit("deleteAllSandGCostRows");
    },
  },
  mounted() {
    if (this.harvestDate) {
      this.startDate = new Date(this?.harvestDate)
        ?.toISOString()
        ?.split("T")[0];
    }
  },
  data() {
    return {
      select: 0,
      startDate: new Date(),
      costParams: {
        priceHa: null,
        costItemFamilyName: "",
        monthsConsideration: 1,
      },
    };
  },
};
</script>
