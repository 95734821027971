<template>
  <AbstractDraftNewSandGCostInput
    :assignedHectares="assignedHectares"
    :activitySliceId="activitySliceId"
    :region="region"
    :families="families"
    :harvestDate="harvestDate"
    :directSliceCostItemsOptions="directSliceCostItemsOptions"
    :deleteAllButtonDisabled="isSandGRowsArrayEmpty"
    @sendNotification="sendNotification"
    @saveEditsToDraftActivitySlice="saveClick"
    @deleteAllSandGCostRows="deleteAllSandGRows"
    @showSandGCostRows="emitShowSandGCostRows"
  />
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import AbstractDraftNewSandGCostInput from "@/components/Abstract/AbstractDraftNewSandGCostInput.vue";
import { postRequest, deleteRequest } from "../../managrx/serverCall";

function isSandGCostSelectable(sliceCostItem, families) {
  return families.some((f) => f === sliceCostItem.costItemFamilyName);
}
export default {
  name: "NewSandGCostInput",
  components: {
    AbstractDraftNewSandGCostInput,
  },
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    region: Number,
    families: Array,
    harvestDate: String,
    directSliceCostItems: Array,
    assignedSandGRows: Array,
  },
  computed: {
    directSliceCostItemsOptions() {
      let directSliceCostItemsFiltered = this.directSliceCostItems.filter(
        (element) => isSandGCostSelectable(element, this.families)
      );
      directSliceCostItemsFiltered = directSliceCostItemsFiltered.map(
        (element) => {
          return { ...element, value: element.name };
        }
      );
      return directSliceCostItemsFiltered;
    },
    isSandGRowsArrayEmpty() {
      return this.assignedSandGRows.length == 0;
    },
  },
  methods: {
    sendNotification(notification) {
      this.$store.dispatch("setNewNotification", notification);
    },
    async saveClick(payloadArray) {
      for (const payload of payloadArray) {
        let response = await postRequest(
          "/assigned_direct_draft_slice_cost_item",
          payload
        );

        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }

      this.$emit("rowSave", payloadArray);
    },
    async deleteAllSandGRows() {
      for (const assignedDirectDraftSliceCostItem of this.assignedSandGRows) {
        console.log(assignedDirectDraftSliceCostItem);
        let response = await deleteRequest(
          "/assigned_direct_draft_slice_cost_item/" +
            assignedDirectDraftSliceCostItem.assignedDirectSliceCostItemId
        );

        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave", null);
    },
    emitShowSandGCostRows() {
      this.$emit("showSandGCostRows");
    },
  },
};
</script>
