<template>
  <AbstractDraftSliceCostInput
    :assignedHectares="assignedHectares"
    :activity="activity"
    title="title"
    :region="region"
    :row="row"
    :hasPaymentPolicy="hasPaymentPolicy"
    :hasFinancing="hasFinancing"
    :families="families"
    :directSliceCostItems="directSliceCostItems"
    :sliceInputCostPeriods="sliceInputCostPeriods"
    :financialPolicies="financialPolicies"
    :interestRates="interestRates"
    @saveClick="rowSave"
    @setNewNotification="setNewNotification"
    @confirmDeleteRow="confirmDeleteClickHandler"
  />
</template>
 
<script>
import { putRequest, deleteRequest } from "@/managrx/serverCall";
import { properties } from "../sharedProps/draftSliceCostItems";
import AbstractDraftSliceCostInput from "../Abstract/AbstractDraftSliceCostInput.vue";

export default {
  name: "SliceCostInput",
  components: { AbstractDraftSliceCostInput },
  props: { ...properties, directSliceCostItems: Array },
  methods: {
    setNewNotification(notification){
      this.$store.dispatch("setNewNotification", notification)
    },
    async rowSave(payload) {
      let assignedId = this.row.assignedDirectSliceCostItemId;
      delete payload.assignedDirectSliceCostItemId;

      let response = await putRequest(
        `/assigned_direct_draft_slice_cost_item/${assignedId}`,
        payload
      );

      if(!response.success){
        this.$store.dispatch("setNewNotification", response)
      }else {
      this.$emit("rowSave", payload);
      }
    },
    async confirmDeleteClickHandler(itemId) {
      let response = await deleteRequest(
        `/assigned_direct_draft_slice_cost_item/${itemId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response)
      } else {
      this.$emit("confirmDeleteRow", itemId);
      }
    },
  },
};
</script>
