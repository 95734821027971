<template>
  <AbstractNewTonBasedCost
    :fixedShareCroppingValue="fixedShareCroppingValue"
    :variableShareCroppingValue="variableShareCroppingValue"
    :assignedHectares="singleDraftActivitySlice.assignedHectares"
    :activitySliceId="singleDraftActivitySlice.id"
    :dynamicYield="singleDraftActivitySlice.summary.dynamicYield"
    :otherTonCostItems="otherTonCostItems"
    @rowSave="handleRowSave"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { postRequest } from "../../managrx/serverCall";
import AbstractNewTonBasedCost from "../Abstract/AbstractNewTonBasedCost.vue";

export default {
  name: "NewTonBasedCost",

  components: { AbstractNewTonBasedCost },
  methods: {
    async handleRowSave(payload) {
      // TODO: Improve component abstraction to share between draft and non draft
      delete payload["activitySliceId"];
      payload["draftActivitySliceId"] = this.singleDraftActivitySlice.id;

      let response = await postRequest(
        "/draft_assigned_other_ton_cost_item",
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave");
      }
    },
  },
  computed: {
    ...mapGetters(["singleDraftActivitySlice", "otherTonCostItems"]),
    fixedShareCroppingValue() {
      return this.singleDraftActivitySlice?.isFixedSharecroppingHandedOver
        ? this.singleDraftActivitySlice?.fixedSharecroppingPercent
        : 0;
    },
    variableShareCroppingValue() {
      return this.singleDraftActivitySlice?.isVariableSharecroppingHandedOver
        ? this.singleDraftActivitySlice?.summary
        ?.variableSharecroppingTotalPercent
        : 0;
    },
  },
};
</script>
