<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="overline mt-2 ml-4 grey--text d-flex">
          Draft Activity Slice
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="ml-4 mb-5">
          <div class="d-flex align-center mb-0 pb-0">
            <h3 class="primary--text" style="font-weight: 400">
              Estado de cosecha
            </h3>
            <span class="ms-2" v-if="harvestComplete"
              ><v-chip small color="info white--text font-weight-bold">
                Completada</v-chip
              ></span
            >
            <span class="ms-2" v-else
              ><v-chip small color="warning white--text font-weight-bold">
                {{ harvestRemainingHaPercent }}% Faltante</v-chip
              ></span
            >
          </div>
        </div>
        <div class="d-flex flex-row">
          <v-text-field
            label="Rinde Dinámico"
            dense
            outlined
            filled
            readonly
            type="number"
            :value="dynamicYield"
            class="managrx-input ma-2 pb-2"
          ></v-text-field>
          <v-text-field
            label="Rinde Esperado"
            dense
            outlined
            filled
            readonly
            type="number"
            :value="forwardYield"
            class="managrx-input ma-2 pb-2"
          ></v-text-field>
          <v-text-field
            label="(0-100)% Ha Cosechadas"
            dense
            outlined
            filled
            readonly
            type="number"
            :value="harvestedHaPercent(totalHarvested)"
            class="managrx-input ma-2 pb-2"
          ></v-text-field>
          <v-text-field
            label="Hectáreas Cosechadas Totales"
            dense
            outlined
            type="number"
            filled
            readonly
            :value="totalHarvested"
            class="managrx-input ma-2 pb-2"
          ></v-text-field>
          <v-text-field
            label="Producción Total"
            dense
            outlined
            type="number"
            filled
            readonly
            :value="totalProduction"
            class="managrx-input ma-2 pb-2"
          ></v-text-field>

          <v-text-field
            label="Fecha de ultimo evento"
            dense
            outlined
            type="date"
            v-model="lastHarvestDate"
            readonly
            filled
            class="managrx-input ma-2 pb-2"
          ></v-text-field>
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <div class="ml-4 mb-5">
          <div class="d-flex align-center mb-0 pb-0">
            <h3
              class=""
              :class="{
                'grey--text': harvestComplete,
                'primary--text': !harvestComplete,
              }"
              style="font-weight: 400"
            >
              Agregar nuevo evento de cosecha
            </h3>
          </div>
        </div>

        <v-form
          :disabled="harvestComplete"
          ref="form1"
          class="d-flex flex-column ml-4 mt-4 pt-4"
        >
          <div class="d-flex flex-row">
            <v-text-field
              label="Hectáreas Cosechadas"
              dense
              outlined
              type="number"
              :rules="harvestedRules"
              v-model="newHarvestEvent.harvestedHa"
              class="managrx-input ma-2 pb-2"
            ></v-text-field>
            <v-text-field
              label="Producción"
              dense
              outlined
              type="number"
              v-model.number="newHarvestEvent.production"
              class="managrx-input ma-2 pb-2"
            ></v-text-field>

            <v-text-field
              label="Rinde Actual"
              dense
              readonly
              filled
              outlined
              type="number"
              :value="
                actualDynamicYield(
                  newHarvestEvent.production,
                  newHarvestEvent.harvestedHa
                )
              "
              class="managrx-input ma-2 pb-2"
            ></v-text-field>

            <v-text-field
              label="Fecha"
              dense
              outlined
              type="date"
              v-model="newHarvestEvent.date"
              class="managrx-input ma-2 pb-2"
            ></v-text-field>

            <v-text-field
              label="(0-100)% Ha"
              dense
              outlined
              filled
              readonly
              type="number"
              :value="harvestedHaPercent(newHarvestEvent.harvestedHa)"
              class="managrx-input ma-2 pb-2"
            ></v-text-field>

            <v-text-field
              label="X"
              dense
              outlined
              filled
              readonly
              type="number"
              :value="
                x(newHarvestEvent.production, newHarvestEvent.harvestedHa)
              "
              class="managrx-input ma-2 pb-2"
            ></v-text-field>
            <v-btn
              elevation="0"
              class="mt-2 mx-4"
              color="primary white--text"
              @click="saveNewHarvestEventClick"
              :disabled="harvestComplete"
            >
              <v-icon> mdi-plus </v-icon></v-btn
            >
          </div>
        </v-form>
      </v-col>
      <v-col>
        <div class="ml-4 mb-5">
          <div class="d-flex align-center mb-0 pb-0">
            <h3 class="primary--text" style="font-weight: 400">
              Eventos previos
            </h3>
          </div>
        </div>
        <div
          class="d-flex flex-row justify-center"
          v-if="parsedHarvestEvents.length == 0"
        >
          <span class="grey--text text-overline pa-5 ma-5"
            >No hay eventos previos de cosecha</span
          >
        </div>
        <v-form ref="form2">
          <div
            v-for="harvestEvent in parsedHarvestEvents"
            :key="harvestEvent.id"
          >
            <div class="d-flex flex-row align-center">
              <div
                class="d-flex flex-row align-center"
                v-if="harvestEvent.id == editId"
              >
                <v-text-field
                  label="Hectáreas Cosechadas"
                  dense
                  outlined
                  type="number"
                  :rules="editHarvestedRules"
                  v-model.number="harvestEvent.harvestedHa"
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>
                <v-text-field
                  label="Producción"
                  dense
                  outlined
                  type="number"
                  v-model.number="harvestEvent.production"
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="Rinde Actual"
                  dense
                  readonly
                  filled
                  outlined
                  type="number"
                  :value="
                    actualDynamicYield(
                      harvestEvent.production,
                      harvestEvent.harvestedHa
                    )
                  "
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="Fecha"
                  dense
                  outlined
                  type="date"
                  v-model="harvestEvent.date"
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="(0-100)% Ha"
                  dense
                  outlined
                  filled
                  readonly
                  type="number"
                  :value="harvestedHaPercent(harvestEvent.harvestedHa)"
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="X"
                  dense
                  outlined
                  filled
                  readonly
                  type="number"
                  :value="x(harvestEvent.production, harvestEvent.harvestedHa)"
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-btn
                  elevation="0"
                  @click="saveHarvestEventEdit(harvestEvent)"
                  color="primary"
                  :depressed="false"
                  class="ml-2 mr-2 mb-9"
                >
                  <v-icon> mdi-floppy</v-icon>
                </v-btn>
                <v-btn
                  elevation="0"
                  color="info white--text"
                  :depressed="false"
                  @click="cancelEditClick"
                  class="mb-9"
                >
                  Atrás
                </v-btn>
              </div>
              <div class="d-flex" v-else>
                <v-text-field
                  label="Hectáreas Cosechadas"
                  dense
                  outlined
                  type="number"
                  :value="harvestEvent.harvestedHa"
                  readonly
                  filled
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>
                <v-text-field
                  label="Producción"
                  dense
                  outlined
                  type="number"
                  :value="harvestEvent.production"
                  readonly
                  filled
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="Rinde Actual"
                  dense
                  readonly
                  filled
                  outlined
                  type="number"
                  :value="
                    actualDynamicYield(
                      harvestEvent.production,
                      harvestEvent.harvestedHa
                    )
                  "
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="Fecha"
                  dense
                  outlined
                  type="date"
                  :value="harvestEvent.date"
                  readonly
                  filled
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="(0-100)% Ha"
                  dense
                  outlined
                  filled
                  readonly
                  type="number"
                  :value="harvestedHaPercent(harvestEvent.harvestedHa)"
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-text-field
                  label="X"
                  dense
                  outlined
                  filled
                  readonly
                  type="number"
                  :value="x(harvestEvent.production, harvestEvent.harvestedHa)"
                  class="managrx-input ma-2 pb-2"
                ></v-text-field>

                <v-btn
                  elevation="0"
                  color="error white--text lighten-1"
                  @click="confirmDeleteClick"
                  :depressed="false"
                  v-if="deleteId == harvestEvent.id"
                  class="mt-2"
                >
                  Confirmar Borrado
                </v-btn>
                <v-btn
                  elevation="0"
                  color="warning"
                  :depressed="false"
                  @click="editClick(harvestEvent.id)"
                  class="mr-2 mt-2"
                  v-else
                >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  elevation="0"
                  color="warning white--text"
                  :depressed="false"
                  @click="cancelDeleteClick"
                  v-if="deleteId == harvestEvent.id"
                  class="ml-2 mt-2"
                >
                  Atrás
                </v-btn>
                <v-btn
                  elevation="0"
                  @click="deleteClick(harvestEvent.id)"
                  color="error white--text"
                  class="mt-2"
                  :depressed="false"
                  v-else
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </v-col>
      <!-- <v-col>
        
      </v-col>-->
    </v-row>
  </div>
</template>

<script>
import { roundToX } from "../../managrx/utils";
export default {
  name: "AbstractDraftHarvestEvent",
  components: {},
  props: {
    harvestEvents: Array,
    assignedHectares: Number,
    forwardYield: Number,
    dynamicYield: Number,
  },
  watch: {
    harvestEvents: function (newVal) {
      // Watch prop for update, parse dates, and sort
      this.parsedHarvestEvents = newVal
        .map((el) => ({ ...el, date: new Date(el.date) }))
        .sort((a, b) => b.date - a.date)
        .map((el) => {
          return { ...el, date: el.date.toISOString().split("T")[0] };
        });
    },
  },

  computed: {
    harvestRemainingHa() {
      return this.assignedHectares - this.totalHarvested;
    },
    harvestRemainingHaPercent() {
      return roundToX(
        (this.harvestRemainingHa * 100) / this.assignedHectares,
        0
      );
    },
    totalHarvested() {
      return this.harvestEvents
        .map((el) => el?.harvestedHa)
        .reduce((a, b) => a + b, 0);
    },
    totalProduction() {
      return this.harvestEvents
        .map((el) => el?.production)
        .reduce((a, b) => a + b, 0);
    },
    lastHarvestDate() {
      if (this.harvestEvents.length > 0) {
        return this.harvestEvents
          .map((el) => ({ ...el, date: new Date(el?.date) }))
          .sort((a, b) => b.date - a.date)[0]
          .date.toISOString()
          .split("T")[0];
      }
      return null;
    },
    harvestComplete() {
      if (this.totalHarvested == this.assignedHectares) {
        return true;
      } else {
        return false;
      }
    },
    editHarvestedRules() {
      let maxValue =
        this.harvestRemainingHa +
        this.harvestEvents.find((el) => el.id == this.editId)?.harvestedHa;
      return [
        ...this.required,
        ...this.minValue,
        (v) =>
          (v && v <= maxValue) || "El valor no puede ser mayor a " + maxValue,
      ];
    },
    harvestedRules() {
      let maxValue = this.harvestRemainingHa;
      return [
        ...this.required,
        ...this.minValue,
        (v) =>
          (v && v <= maxValue) || "El valor no puede ser mayor a " + maxValue,
      ];
    },
  },
  methods: {
    actualDynamicYield(production, harvestedHa) {
      if (harvestedHa != 0) {
        let result = roundToX(production / harvestedHa, 0);
        if (result != undefined && result != null) {
          return result;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },

    harvestedHaPercent(harvestedHa) {
      if (this.assignedHectares != 0) {
        let result = roundToX((harvestedHa * 100) / this.assignedHectares, 2);
        if (result != undefined && result != null) {
          return result;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    x(production, harvestedHa) {
      return roundToX(
        (this.harvestedHaPercent(harvestedHa) / 100) *
          this.actualDynamicYield(production, harvestedHa),
        0
      );
    },
    saveNewHarvestEventClick() {
      if (this.$refs.form1.validate()) {
        let payload = {
          ...this.newHarvestEvent,
          date: new Date(this.newHarvestEvent?.date).toISOString(),
        };
        if (
          this.totalHarvested + this.newHarvestEvent?.harvestedHa ==
          this.assignedHectares
        ) {
          this.$emit("harvestComplete");
        }
        this.$emit("saveNewHarvestEvent", payload);
        this.newHarvestEvent = {
          date: new Date().toISOString().split("T")[0],
          harvestedHa: 0,
          production: 0,
        };
        this.$refs.form1.resetValidation();
      }
    },
    editClick(id) {
      this.editId = id;
    },
    cancelEditClick() {
      this.editId = null;
      this.parsedHarvestEvents = this.harvestEvents
        .map((el) => ({ ...el, date: new Date(el.date) }))
        .sort((a, b) => b.date - a.date)
        .map((el) => {
          return { ...el, date: el.date.toISOString().split("T")[0] };
        });
    },
    saveHarvestEventEdit(harvestEvent) {
      if (this.$refs.form2.validate()) {
        let payload = {
          id: harvestEvent?.id,
          row: {
            harvestedHa: harvestEvent?.harvestedHa,
            production: harvestEvent?.production,
            date: new Date(harvestEvent?.date).toISOString(),
          },
        };
        let oldHarvestedHaValue = this.harvestEvents.find(
          (el) => el.id == harvestEvent?.id
        ).harvestedHa;
        let newTotalHarvested =
          this.totalHarvested - oldHarvestedHaValue + harvestEvent?.harvestedHa;
        console.log(newTotalHarvested);
        if (newTotalHarvested == this.assignedHectares) {
          this.$emit("harvestComplete");
        }
        this.$emit("saveHarvestEventEdit", payload);
        this.editId = null;
        this.$refs.form2.resetValidation();
      }
    },
    deleteClick(id) {
      this.deleteId = id;
    },
    cancelDeleteClick() {
      this.deleteId = null;
    },
    confirmDeleteClick() {
      this.$emit("deleteHarvestEvent", this.deleteId);
      this.deleteId = null;
    },
  },
  mounted() {
    this.parsedHarvestEvents = this.harvestEvents
      .map((el) => ({ ...el, date: new Date(el.date) }))
      .sort((a, b) => b.date - a.date)
      .map((el) => {
        return { ...el, date: el.date.toISOString().split("T")[0] };
      });
  },
  data() {
    return {
      parsedHarvestEvents: [],
      editId: null,
      deleteId: null,
      required: [(v) => !!v || "Este campo es obligatorio"],
      minValue: [(v) => v >= 0 || "El valor no puede ser mayor a 0"],
      newHarvestEvent: {
        date: new Date().toISOString().split("T")[0],
        harvestedHa: 0,
        production: 0,
      },
    };
  },
};
</script>
