<template>
  <AbstractIncomeBasedCostInput
    :isPct="true"
    :incomeDate="singleDraftActivitySlice.incomeDate"
    :componentRelevantValue="singleDraftActivitySlice.technicalDirection"
    :activitySliceId="singleDraftActivitySlice.id"
    :firstLabel="'Dir. Técnica'"
    :secondLabel="'Fecha de ingreso'"
    :isDraft="true"
    @saveClick="saveClick"
  />
</template>

<script>
import AbstractIncomeBasedCostInput from "../Abstract/AbstractIncomeBasedCostInput.vue";
import { putRequest } from "../../managrx/serverCall";
import { mapGetters } from "vuex";

export default {
  name: "TechnicalDirectionCostInput",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    title: String,
    region: Number,
    activity: Number,
    families: Array,
  },
  methods: {
    async saveClick(payload) {

      let response = await putRequest("/draft_activity_slice/" + payload.id, {
        technicalDirection: payload.newValue,
      });

      if(!response.success){
        this.$store.dispatch("setNewNotification", response)
      }else {
      this.$emit("rowSave");
      }
    },
  },
  components: {
    AbstractIncomeBasedCostInput,
  },
  computed: {
    ...mapGetters(["singleDraftActivitySlice"]),
  },
};
</script>
