<template>
  <v-form class="pr-2 pl-2" v-model="valid" lazy-validation ref="form">
    <div class="d-flex">
      <v-text-field
        label="Familia"
        outlined
        dense
        filled
        class="managrx-input mr-2"
        :value="newRow.family"
        readonly
      ></v-text-field>
      <v-select
        ref="itemSelect"
        v-model="newRow.directSliceCostItemId"
        :items="selectableDraftCostItems"
        @change="directSliceCostItemSelectChange"
        item-text="name"
        item-value="id"
        label="Insumo"
        :rules="required"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>
      <v-text-field
        label="Precio"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        v-model.number="newRow.unitPrice"
        :rules="priceRules"
        @change="computeSubtotal"
      ></v-text-field>

      <v-select
        ref="periodSelect"
        v-model="newRow.costInputPeriodId"
        open-on-clear
        :items="sliceInputCostPeriods"
        item-text="name"
        item-value="id"
        label="Periodo"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>
      <v-text-field
        label="Hectáreas Asignadas"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="assignedHectares"
        readonly
        filled
        @change="computeSubtotal"
      ></v-text-field>

      <v-text-field
        label="(0-100)% App"
        outlined
        append-icon="mdi-percent-outline"
        dense
        :rules="percentAppRules"
        type="number"
        class="managrx-input mr-2"
        v-model.number="newRow.percentApplicationAsInt"
        @change="computeSubtotal"
      ></v-text-field>

      <v-text-field
        label="Unidades x Hectáreas"
        v-model.number="newRow.units"
        :rules="unitsRules"
        type="number"
        outlined
        dense
        class="managrx-input mr-2"
        @change="computeSubtotal"
      ></v-text-field>

      <v-text-field
        label="Unidad de Medida"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
        :value="newRow.unitMeasure"
      ></v-text-field>
      <v-text-field
        label="Subtotal"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
        :value="newRow.subTotal"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field
        class="managrx-input mr-2"
        outlined
        dense
        :rules="dateUsedRules"
        label="Fecha de uso"
        v-model="newRow.dateUsed"
        type="date"
      ></v-text-field>

      <v-text-field
        class="managrx-input mr-2"
        outlined
        dense
        label="Fecha de pago"
        :rules="datePaidRules"
        v-model="newRow.datePaid"
        v-if="!hasPaymentPolicy"
        type="date"
      ></v-text-field>
      <v-select
        v-if="hasPaymentPolicy"
        ref="paymentPolicySelect"
        v-model="newRow.paymentPolicyId"
        open-on-clear
        :rules="required"
        :items="financialPolicies"
        item-text="name"
        item-value="id"
        label="Política de pago"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>

      <v-select
        ref="interestSelect"
        v-model="newRow.interestRateId"
        open-on-clear
        :items="interestRates"
        item-text="name"
        item-value="id"
        label="Financiación"
        dense
        outlined
        class="managrx-input mr-2"
        :readonly="!hasFinancing"
        :filled="hasPaymentPolicy"
      ></v-select>

      <v-btn
        depressed
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy </v-icon>
      </v-btn>
      <v-btn depressed color="info" class="mr-2" @click="resetNewRow">
        <v-icon> mdi-restart</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import useSharedSliceCosts from "@/mixins/useSharedSliceCosts";
import { postRequest } from "../../managrx/serverCall";
import {
  parseToLocaleString,
  roundToX,
  validateForm,
} from "../../managrx/utils";
import { NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES } from "../../managrx/formsFieldsRules";

export default {
  name: "NewSliceCostInput",
  mixins: [useSharedSliceCosts],
  props: {
    variableParticipationDate: String,

    assignedHectares: Number,
    activitySliceId: Number,
    title: String,
    region: Number,
    activity: Number,
    campaign: Number,
    families: Array,
    hasPaymentPolicy: Boolean,
    hasFinancing: Boolean,
    directSliceCostItems: Array,
    sliceInputCostPeriods: Array,
    financialPolicies: Array,
    interestRates: Array,
  },
  methods: {
    computeSubtotal() {
      console.log("Computing Sub Total!");
      this.newRow.subTotal =
        parseToLocaleString(
          roundToX(
            this.newRow.unitPrice *
              this.newRow.units *
              this.assignedHectares *
              this.percentAppAsDecimal,
            2
          )
        ) ?? 0;
    },
    directSliceCostItemSelectChange(itemId) {
      let candidate = this.directSliceCostItems.find(
        (item) => item.id === itemId
      );
      console.log(candidate);
      this.newRow.family = candidate.costItemFamilyName;
      this.newRow.unitMeasure = candidate.unitMeasure;
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        let payload = {
          draftActivitySliceId: this.activitySliceId,
          directSliceCostItemId: this.newRow.directSliceCostItemId,
          unitPrice: this.newRow.unitPrice,
          percentApplication: this.percentAppAsDecimal,
          costInputPeriodId: this.newRow.costInputPeriodId,
          unitsPerHectare: this.newRow.units,
          interestRateId: this.newRow.interestRateId,
          dateUsed: new Date(this.newRow.dateUsed).toISOString(),
        };

        if (this.paymentOption === 1) {
          payload.datePaid = new Date(this.newRow.datePaid).toISOString();
        } else {
          payload.paymentPolicyId = this.newRow.paymentPolicyId;
        }
        let response = await postRequest(
          `/assigned_direct_draft_slice_cost_item`,
          payload
        );

        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          this.$emit("rowSave");
        }
      }
    },
    resetNewRow() {
      this.newRow = {
        directSliceCostItemId: null,
        costInputPeriodId: 1,
        interestRateId: null,
        paymentPolicyId: null,
        price: 0,
        percentApplicationAsInt: 100,
        family: "",
        units: 1,
        unitMeasure: "kg",
        subTotal: 0,
        dateUsed: this.variableParticipationDate
          ? this.variableParticipationDate?.split("T")?.[0]
          : new Date().toISOString().split("T")[0],
        datePaid: this.variableParticipationDate
          ? this.variableParticipationDate?.split("T")?.[0]
          : new Date().toISOString().split("T")[0],
        rate: 0,
      };
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    percentAppAsDecimal() {
      return this.newRow.percentApplicationAsInt / 100;
    },
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    priceRules() {
      if (!this.newRow.unitPrice && this.newRow.unitPrice != 0) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES?.price;
      let detail = validateForm(this.newRow.unitPrice, fieldRules);
      return detail;
    },
    percentAppRules() {
      if (
        !this.newRow.percentApplicationAsInt &&
        this.newRow.percentApplicationAsInt != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES?.percentApp;
      let detail = validateForm(
        this.newRow.percentApplicationAsInt,
        fieldRules
      );
      return detail;
    },
    unitsRules() {
      if (!this.newRow.units && this.newRow.units != 0) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES?.units;
      let detail = validateForm(this.newRow.units, fieldRules);
      return detail;
    },
    dateUsedRules() {
      if (!this.newRow.dateUsed) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES?.dateUsed;
      let detail = validateForm(this.newRow.dateUsed, fieldRules);
      return detail;
    },
    datePaidRules() {
      if (!this.newRow.datePaid) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES?.dateUsed;
      let detail = validateForm(this.newRow.datePaid, fieldRules);
      return detail;
    },
    paymentOption() {
      return this.hasPaymentPolicy ? 2 : 1;
    },
  },
  data() {
    return {
      valid: true,
      checkbox: true,
      select: "",
      paymentPolicySelect: null,
      selectedPeriod: null,
      selectedInterest: null,
      newRow: {
        directSliceCostItemId: null,
        costInputPeriodId: 1,
        interestRateId: null,
        paymentPolicyId: null,
        price: 0,
        percentApplicationAsInt: 100,
        family: "",
        units: 1,
        unitMeasure: "kg",
        subTotal: 0,
        dateUsed: this.variableParticipationDate
          ? this.variableParticipationDate?.split("T")?.[0]
          : new Date().toISOString().split("T")[0],
        datePaid: this.variableParticipationDate
          ? this.variableParticipationDate?.split("T")?.[0]
          : new Date().toISOString().split("T")[0],
        rate: 0,
      },
    };
  },
};
</script>
