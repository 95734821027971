<template>
  <AbstractIncomeBasedCostInput
    :isPct="true"
    :incomeDate="singleDraftActivitySlice.incomeDate"
    :componentRelevantValue="singleDraftActivitySlice.saleTax"
    :firstLabel="'Impuestos de venta'"
    :secondLabel="'Fecha de ingresos'"
    :activitySliceId="singleDraftActivitySlice.id"
    :isDraft="true"
    @saveClick="saveClick"
  />
</template>

<script>
import AbstractIncomeBasedCostInput from "../Abstract/AbstractIncomeBasedCostInput.vue";
import { putRequest } from "../../managrx/serverCall";
import { mapGetters } from "vuex";

export default {
  name: "SaleTaxCostInput",
  components: {
    AbstractIncomeBasedCostInput,
  },
  methods: {
    async saveClick(payload) {
      let response = await putRequest("/draft_activity_slice/" + payload.id, {
        saleTax: payload.newValue,
      });

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave");
      }
    },
  },
  computed: {
    ...mapGetters(["singleDraftActivitySlice"]),
  },
};
</script>
