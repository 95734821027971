<template>
  <div>
    <v-row>
      <v-col>
        <div>
          <h1
            class="ml-2 mb-2 display-2 hover-green"
            style="font-weight: 200"
            @click="
              $router.push(
                `/draft-engagement/${singleDraftActivitySlice.engagement.id}`
              )
            "
          >
            {{ draftName }}
          </h1>

          <v-chip class="ml-2" color="primary">
            <h3 class="ml-2" style="font-weight: 400">
              <v-icon>mdi-sprout</v-icon> {{ activityName }}
            </h3>
          </v-chip>
          <v-chip class="ml-2" color="secondary">
            <h3 class="ml-2" style="font-weight: 400">
              <v-icon>mdi-home</v-icon> {{ establishmentName }}
            </h3>
          </v-chip>
          <v-chip class="ml-2">
            Arrendamientos
            {{ engagementRentSummaryText }}
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!isPageLoading">
      <v-col>
        <v-divider class="mb-4 mt-2"></v-divider>
        <InfoGrid
          :infos="singleDraftActivitySliceInfoGrid"
          infoTitle="KPIs"
          infoTitleClass="grey--text h2"
          valueClass="display-2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Insumos</b
          >
        </div>

        <NewSliceCostInput
          :variableParticipationDate="variableParticipationDate"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          @rowSave="sliceCostInputRowSaveHandler"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          :families="insumos"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :directSliceCostItems="directSliceCostItems"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
          :hasFinancing="true"
          :hasPaymentPolicy="false"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isPageLoading">
      <v-col>
        <SliceCostInput
          :row="assignedRow"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          @rowSave="sliceCostInputRowSaveHandler"
          @confirmDeleteRow="sliceCostInputRowSaveHandler"
          v-for="assignedRow in displayableAssignedInsumos"
          :key="assignedRow.assignedDirectSliceCostItemId"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          :families="insumos"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :directSliceCostItems="directSliceCostItems"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
        />
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Servicios</b
          >
        </div>
        <NewSliceCostInput
          :variableParticipationDate="variableParticipationDate"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          @rowSave="sliceCostInputRowSaveHandler"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          :families="servicios"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :directSliceCostItems="directSliceCostItems"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
          :hasFinancing="false"
          :hasPaymentPolicy="true"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isPageLoading">
      <v-col>
        <SliceCostInput
          :row="assignedRow"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          @rowSave="sliceCostInputRowSaveHandler"
          @confirmDeleteRow="sliceCostInputRowSaveHandler"
          v-for="assignedRow in displayableAssignedServicios"
          :key="assignedRow.assignedDirectSliceCostItemId"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          :families="servicios"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :directSliceCostItems="directSliceCostItems"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
        />
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Seguimiento y Control</b
          >
        </div>
        <SandGSliceSummary :assignedSandGRows="displayableAssignedSandG" />
        <NewSandGCostInput
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          :harvestDate="singleDraftActivitySlice.harvestDate"
          :region="regionId"
          :families="seguimientosYControles"
          :directSliceCostItems="directSliceCostItems"
          :assignedSandGRows="displayableAssignedSandG"
          :deleteAllButtonDisabled="true"
          @rowSave="sliceCostInputRowSaveHandler"
          @showSandGCostRows="showSandGCostRows"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isPageLoading">
      <v-col>
        <SliceCostInput
          v-show="isSandGRowsViewable"
          v-for="assignedRow in displayableAssignedSandG"
          :row="assignedRow"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          :key="assignedRow.assignedDirectSliceCostItemId"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          :families="seguimientosYControles"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
          :directSliceCostItems="directSliceCostItems"
          @rowSave="sliceCostInputRowSaveHandler"
          @confirmDeleteRow="sliceCostInputRowSaveHandler"
        />

        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Varios</b
          >
        </div>
        <NewSliceCostInput
          :variableParticipationDate="variableParticipationDate"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          @rowSave="sliceCostInputRowSaveHandler"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          :families="varios"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :directSliceCostItems="directSliceCostItems"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
          :hasFinancing="false"
          :hasPaymentPolicy="false"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isPageLoading">
      <v-col>
        <SliceCostInput
          :row="assignedRow"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          @rowSave="sliceCostInputRowSaveHandler"
          @confirmDeleteRow="sliceCostInputRowSaveHandler"
          v-for="assignedRow in displayableAssignedVariosCosts"
          :key="assignedRow.assignedDirectSliceCostItemId"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          :families="varios"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :directSliceCostItems="directSliceCostItems"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
        />
      </v-col>
    </v-row>
    <v-row v-if="nullInstallment">
      <v-col cols="12">
        <v-divider></v-divider>
        <div class="mt-2">
          <span class="mr-2">
            <b class="grey--text h2">
              <v-icon> mdi-home </v-icon> Arrendamientos</b
            >
          </span>
        </div>

        <div class="d-flex flex-row justify-center my-4">
          <span
            class="text-overline grey--text text-subtitle-2"
            style="font-size: 18px !important"
          >
            Arrendamineto no establecido
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="!isFixedUsdInstallment">
      <v-col cols="12">
        <v-divider></v-divider>
        <div class="mt-2">
          <span class="mr-2">
            <b class="grey--text h2">
              <v-icon> mdi-home </v-icon> Arrendamientos</b
            >
          </span>
          <span v-if="!hasRentBonus">
            <v-chip small class="info white--text font-weight-medium"
              >Sin bonificación para {{ activityName }}</v-chip
            >
          </span>
          <span v-else>
            <v-chip small class="info white--text font-weight-medium me-2">
              Bonificación {{ activityName }}
            </v-chip>
            <v-chip
              v-for="bonusDetail in parsedRentBonification"
              :key="bonusDetail"
              small
              class="primary white--text font-weight-bold me-2"
            >
              {{ bonusDetail }}</v-chip
            >
          </span>
        </div>
        <div v-if="rentPaymentPlanItems.length">
          <v-data-table
            :headers="rentPaymentPlanKeys"
            :items="rentPaymentPlanItems"
            :items-per-page="15"
            hide-default-footer
            fixed-header
            dense
            class="elevation-0"
            ><template v-slot:top>
              <v-toolbar text elevation="0">
                <v-toolbar-title class="text-subtitle-1 font-weight-light"
                  >Planeadas</v-toolbar-title
                >
              </v-toolbar>
            </template></v-data-table
          >
        </div>
        <div v-else class="d-flex flex-row justify-center my-5">
          <span
            class="text-overline grey--text"
            style="font-size: 18px !important"
          >
            No hay quintales planeados establecidos
          </span>
        </div>

        <div v-if="rentFixedPaymentItems.length">
          <v-data-table
            :headers="rentFixedPaymentKeys"
            :items="rentFixedPaymentItems"
            :items-per-page="15"
            hide-default-footer
            fixed-header
            dense
            class="elevation-0"
            ><template v-slot:top>
              <v-toolbar text elevation="0">
                <v-toolbar-title class="text-subtitle-1 font-weight-light"
                  >Fijaciones</v-toolbar-title
                >
              </v-toolbar>
            </template></v-data-table
          >
        </div>
        <div v-else class="d-flex flex-row justify-center my-5">
          <span
            class="text-overline grey--text"
            style="font-size: 18px !important"
          >
            No hay fijaciones establecidas
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-divider></v-divider>
        <div class="mt-2">
          <span class="mr-2">
            <b class="grey--text h2">
              <v-icon> mdi-home </v-icon> Arrendamientos</b
            >
          </span>
        </div>

        <div class="d-flex flex-row justify-center my-4">
          <span class="text-overline grey--text text-subtitle-2">
            Arrendamiento en DOLARES FIJOS
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2 me-2">
            <v-icon> mdi-barn </v-icon>
            Aparcería Simple</b
          >
          <span v-if="!singleDraftActivitySlice.isFixedSharecroppingHandedOver">
            <v-chip small class="info white--text font-weight-medium"
              >No entrega KG</v-chip
            >
          </span>
          <span v-else>
            <v-chip small class="primary white--text font-weight-medium"
              >Entrega KG</v-chip
            >
          </span>
        </div>
        <SimpleShareCropping
          @rowSave="simpleShareCroppingInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2 me-2">
            <v-icon> mdi-barn </v-icon>
            Aparcería Variable</b
          >
          <span
            v-if="!singleDraftActivitySlice.isVariableSharecroppingHandedOver"
          >
            <v-chip small class="info white--text font-weight-medium"
              >No entrega KG</v-chip
            >
          </span>
          <span v-else>
            <v-chip small class="primary white--text font-weight-medium"
              >Entrega KG</v-chip
            >
          </span>
        </div>
        <VariableShareCroppingSummary
          :variableShareCroppingRows="draftVariableShareCropSchedule"
          :activitySliceForwardYield="singleDraftActivitySlice.forwardYield"
        />
        <NewVariableShareCropping
          :draftActivitySliceId="parseInt($route.params.id)"
          :variableShareCroppingRows="draftVariableShareCropSchedule"
          @rowSave="variableShareCroppingInputRowSaveHandler"
          @variableSharecroppingInterpolationRowSave="
            variableShareCroppingInputRowSaveHandler
          "
          @showVariableShareCroppingSchedule="showVariableShareCroppingSchedule"
        />

        <div v-show="isVariableShareCroppingScheduleViewable">
          <VariableShareCropping
            v-for="(row, i) in draftVariableShareCropSchedule"
            :key="i"
            :row="row"
            :dynamicYield="singleDraftActivitySlice.summary.dynamicYield"
            @confirmDeleteRow="variableShareCroppingInputRowSaveHandler"
            @saveClick="variableShareCroppingInputRowSaveHandler"
          />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2">
            <v-icon> mdi-tractor </v-icon>
            Cosecha
          </b>
        </div>
        <HarvestCostInput
          @putResponseSuccess="harvestCostInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2">
            <v-icon> mdi-truck </v-icon>
            Fletes</b
          >
        </div>
        <FreightCostInput
          @putResponseSuccess="freightCostInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row v-if="!isPageLoading">
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-weight </v-icon>
            Embolsado/Acondicionamiento</b
          >
        </div>
        <NewTonBasedCost @rowSave="tonCostInputRowSaveHandler" />

        <TonCostInput
          :row="row"
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          @rowSave="tonCostInputRowSaveHandler"
          @confirmDeleteRow="tonCostInputRowSaveHandler"
          v-for="(row, i) in tonCostItemsRows"
          :key="i"
        />
      </v-col>
    </v-row>

    <v-row v-if="!isPageLoading">
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-account-supervisor-circle </v-icon>
            Dirección Técnica</b
          >
        </div>
        <TechnicalDirectionCostInput
          :assignedHectares="singleDraftActivitySlice.assignedHectares"
          :activitySliceId="singleDraftActivitySlice.id"
          :region="regionId"
          :activity="singleDraftActivitySlice.activityId"
          @rowSave="sliceCostInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row v-if="!isPageLoading">
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-cart-arrow-up </v-icon>
            Gastos de Venta</b
          >
        </div>
        <SaleTaxCostInput @rowSave="sliceCostInputRowSaveHandler" />
        <ComissionCostInput @rowSave="sliceCostInputRowSaveHandler" />
        <HandOverCostInput @rowSave="sliceCostInputRowSaveHandler" />
      </v-col>
    </v-row>
  </div>
</template>

<style>
.v-application .display-1 {
  font-size: 28px !important;
}
.hover-green:hover {
  color: green;
}
</style>

<script>
import InfoGrid from "../Project/InfoGrid.vue";
import ComissionCostInput from "./ComissionCostInput.vue";
import HandOverCostInput from "./HandOverCostInput.vue";
import SaleTaxCostInput from "./SaleTaxCostInput.vue";
import NewVariableShareCropping from "../Draft/NewVariableShareCropping.vue";
import NewSliceCostInput from "../Draft/NewSliceCostInput.vue";
import NewSandGCostInput from "./NewSandGCostDraftInput.vue";
import SandGSliceSummary from "../SharedProjectAndDraft/SandGSliceSummary.vue";
import SliceCostInput from "../Draft/SliceCostInput.vue";
import SimpleShareCropping from "../Draft/SimpleShareCropping.vue";
import HarvestCostInput from "../Draft/HarvestCostInput.vue";
import FreightCostInput from "../Draft/FreightCostInput.vue";
import VariableShareCropping from "../Draft/VariableShareCropping.vue";
import VariableShareCroppingSummary from "../SharedProjectAndDraft/VariableShareCroppingSummary.vue";

import TechnicalDirectionCostInput from "../Draft/TechnicalDirectionCostInput.vue";
import NewTonBasedCost from "../Draft/NewTonBasedCost.vue";
import TonCostInput from "../Draft/TonCostInput.vue";
import { mapGetters } from "vuex";
import { putRequest } from "../../managrx/serverCall";
import {
  insumos,
  servicios,
  seguimientosYControles,
  varios,
  DRAFT_FIXED_USD_CONTRACT,
} from "../../managrx/constants";
insumos, servicios, seguimientosYControles;

function handleResponse(response) {
  if (!response.success) {
    this.$store.dispatch("setNewNotification", response);
  }
}

function processAssignedCostItems(sliceCostItems) {
  if (sliceCostItems?.rows == null) {
    return [];
  }

  return sliceCostItems?.rows
    .map((i) => {
      return {
        ...i,
        subTotal: i.itemCost,
        family: i.costItemFamilyName,
        units: i.unitsPerHectare,
        dateUsed: i.dateUsed.split("T")[0],
        datePaid: i.datePaid ? i.datePaid.split("T")[0] : null,
        selectedRate: i.interestRateId
          ? `${i.interestRateName} (${i.rate})`
          : null,
      };
    })
    .sort((a, b) => a.naturalOrder - b.naturalOrder);
}
export default {
  name: "SingleDraftActivitySlicePage1",
  components: {
    InfoGrid,
    SliceCostInput,
    NewSliceCostInput,
    SimpleShareCropping,
    HarvestCostInput,
    FreightCostInput,
    TechnicalDirectionCostInput,
    NewVariableShareCropping,
    VariableShareCropping,
    VariableShareCroppingSummary,
    NewSandGCostInput,
    SandGSliceSummary,
    NewTonBasedCost,
    TonCostInput,
    ComissionCostInput,
    HandOverCostInput,
    SaleTaxCostInput,
  },
  props: { variableParticipationDate: String },
  data() {
    return {
      foo: "bar",
      sortKey: "costItemNaturalOrder",
      isVariableShareCroppingScheduleViewable: false,
      isSandGRowsViewable: false,
    };
  },
  computed: {
    ...mapGetters([
      "singleDraftActivitySlice",
      "singleDraftActivitySliceInfoGrid",
      "draftSliceCostItems",
      "financialPolicies",
      "interestRates",
      "draftVariableShareCropSchedule",
      "sliceInputCostPeriods",
      "directSliceCostItems",
    ]),
    hasRentBonus() {
      return !(
        this.singleDraftActivitySlice?.rentBonus === null ||
        this.singleDraftActivitySlice?.rentBonus?.rentBonusPercent === 0
      );
    },
    parsedRentBonification() {
      // Only one bonus is allowed by AS
      let bonus = this.singleDraftActivitySlice?.rentBonus;
      let parsedBonus = [];
      if (bonus) {
        if (bonus.isUsdHa) {
          parsedBonus.push(`USD / Ha`);
        } else {
          parsedBonus.push(`KG / Ha`);
        }
        parsedBonus.push(`Bonificación ${bonus?.rentBonusPercent * 100}%`);
        parsedBonus.push(`Rinde Bonificado ${bonus?.yieldThreshold}`);
      }
      return parsedBonus;
    },
    nullInstallment() {
      return this.singleDraftActivitySlice?.engagement?.contractType == null;
    },
    isFixedUsdInstallment() {
      return (
        this.singleDraftActivitySlice?.engagement?.contractType ==
        DRAFT_FIXED_USD_CONTRACT.value
      );
    },
    draftName() {
      return this.singleDraftActivitySlice?.engagement?.draftName;
    },
    isPageLoading() {
      return this.$store.getters.isPageLoading;
    },
    insumos() {
      return insumos;
    },
    servicios() {
      return servicios;
    },
    varios() {
      return varios;
    },
    seguimientosYControles() {
      return seguimientosYControles;
    },
    sliceCostItemsRows() {
      // Converts snake case to camel to render individual assigned cost componenets
      return processAssignedCostItems(this.draftSliceCostItems);
    },
    engagementId() {
      return this?.singleDraftActivitySlice?.engagement?.id;
    },
    establishmentName() {
      return this?.singleDraftActivitySlice?.engagement?.establishment?.name;
    },
    engagementRentSummaryText() {
      let as = this?.singleDraftActivitySlice;
      let e = this?.singleDraftActivitySlice?.engagement;
      let pricePerH =
        (as?.rentPercentApplication * e?.rentQqHa * e?.rentQqActivityPrice) /
        10;
      return `${as?.rentPercentApplication * 100}% x ${e?.rentQqHa} qq/ha @ $${
        e?.rentQqActivityPrice
      } = $${pricePerH}/ha`;
    },
    displayableAssignedInsumos() {
      return this.sliceCostItemsRows?.filter((r) =>
        insumos.some((i) => i === r.costItemFamilyName)
      );
    },
    displayableAssignedServicios() {
      return this.sliceCostItemsRows?.filter((r) =>
        servicios.some((i) => i === r.costItemFamilyName)
      );
    },
    displayableAssignedVariosCosts() {
      return this.sliceCostItemsRows?.filter((r) =>
        varios.some((i) => i === r.costItemFamilyName)
      );
    },
    displayableAssignedSandG() {
      return this.sliceCostItemsRows?.filter((r) =>
        seguimientosYControles.some((i) => i === r.costItemFamilyName)
      );
    },
    regionId() {
      return this.singleDraftActivitySlice?.engagement?.id;
    },
    rentPaymentPlanKeys() {
      return [
        { text: "Fecha Cuota", value: "paymentDate" },
        { text: "Porcentaje de App", value: "rentPercentApplication" },
        { text: "Cuota Pago Total", value: "engagementQqHa" },
        { text: "Cuota Pago Aplicado", value: "qqHa" },
        { text: "Quintales", value: "quintals" },
        { text: "Monto Pago", value: "paymentAmount" },
        { text: "Monto Pago / ha", value: "paymentAmountByHectare" },
        { text: "Precio en adelanto", value: "forwardPrice" },
      ];
    },
    rentFixedPaymentKeys() {
      return [
        { text: "Fecha Cuota", value: "paymentDate" },
        { text: "Porcentaje de App", value: "rentPercentApplication" },
        { text: "Cuota Pago Total", value: "engagementQqHa" },
        { text: "Cuota Pago Aplicado", value: "qqHa" },
        { text: "Quintales Fijados", value: "quintals" },
        { text: "Monto  ", value: "paymentAmount" },
        { text: "Monto Pago / ha", value: "paymentAmountByHectare" },
        { text: "Precio de Fijación", value: "fixedPrice" },
      ];
    },
    rentFixedPaymentItems() {
      return this.singleDraftActivitySlice?.rentFixedPayment?.items;
    },
    rentPaymentPlanItems() {
      return this.singleDraftActivitySlice?.rentPaymentPlan?.items;
    },

    tonCostItemsRows() {
      return this.singleDraftActivitySlice?.assignedTonCosts;
    },
    activityName() {
      return this.singleDraftActivitySlice?.summary?.activityName;
    },
  },
  methods: {
    showVariableShareCroppingSchedule() {
      this.isVariableShareCroppingScheduleViewable =
        !this.isVariableShareCroppingScheduleViewable;
    },
    showSandGCostRows() {
      this.isSandGRowsViewable = !this.isSandGRowsViewable;
    },
    sliceCostInputRowSaveHandler(event) {
      // Handler used to refresh assigned slice cost item rows from database to store
      event;
      this.$store.dispatch("updateDraftSASliceCache", "assignedSCostItems");
      this.$store.dispatch("updateDraftSASliceCache", "summary");

      this.$emit("refreshCashflow");
    },
    tonCostInputRowSaveHandler(event) {
      // Handler used to refresh assigned slice cost item rows from database to store
      event;
      this.$store.dispatch("updateDraftSASliceCache", "assignedTonCosts");
      this.$store.dispatch("updateDraftSASliceCache", "summary");

      this.$emit("refreshCashflow");
    },

    harvestCostInputRowSaveHandler(event) {
      event;
      this.$store.dispatch("updateDraftSASliceCache", "summary");

      this.$emit("refreshCashflow");
    },
    simpleShareCroppingInputRowSaveHandler(event) {
      event;
      this.$store.dispatch("updateDraftSASliceCache", "summary");

      this.$emit("refreshCashflow");
    },
    variableShareCroppingInputRowSaveHandler(event) {
      event;
      this.$store.dispatch("updateDraftSASliceCache", "variableShareCrop");
      this.$store.dispatch("updateDraftSASliceCache", "summary");

      this.$emit("refreshCashflow");
    },
    freightCostInputRowSaveHandler(event) {
      event;
      this.$store.dispatch("updateDraftSASliceCache", "summary");

      this.$emit("refreshCashflow");
    },
    rentCostInputRowSaveHandler(event) {
      console.log(event);
    },
    rentCostInputRowDeleteHandler() {
      this.$store.dispatch("updateDraftSASliceCache", "summary");
      this.$store.dispatch("updateDraftSASliceCache", "rents");

      this.$emit("refreshCashflow");
    },
    async updateSaleTaxById(payload) {
      let response = await putRequest(`/draft_activity_slice/${payload.id}`, {
        saleTax: payload.newValue,
      });
      handleResponse(response);

      this.$emit("refreshCashflow");
    },
    async updateSaleComissionById(payload) {
      let response = await putRequest(`/draft_activity_slice/${payload.id}`, {
        saleComission: payload.newValue,
      });
      handleResponse(response);

      this.$emit("refreshCashflow");
    },
    async updateSaleHandOverById(payload) {
      let response = await putRequest(`/draft_activity_slice/${payload.id}`, {
        saleHandOver: payload.newValue,
      });
      handleResponse(response);

      this.$emit("refreshCashflow");
    },
  },
};
</script>
